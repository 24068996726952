import * as React from "react";

function Pluto(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-pluto"
      role="img"
      {...props}
    >
      <title id="component-svg-pluto">Pluto</title>
      <path
        d="M11 14.945V18H9.2a1 1 0 000 2H11v2a1 1 0 002 0v-2h2a1 1 0 000-2h-2v-3.055c4.5-.497 8-4.312 8-8.945a1 1 0 00-2 0A7 7 0 015 6a1 1 0 10-2 0c0 4.633 3.5 8.448 8 8.945zM12 10a4 4 0 100-8 4 4 0 000 8zm0-2a2 2 0 110-4 2 2 0 010 4z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Pluto;
