import * as React from "react";

function Sagittarius(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-sagittarius"
      role="img"
      {...props}
    >
      <title id="component-svg-sagittarius">Sagittarius</title>
      <path
        d="M19.006 6.475v5.5a.997.997 0 001.994 0V3.997A.997.997 0 0020.003 3h-7.978a.997.997 0 000 1.994h5.5L9.57 12.95l-1.714-1.714a1.047 1.047 0 00-1.48 1.48l1.713 1.715-4.782 4.783a1.047 1.047 0 001.48 1.48l4.783-4.782 1.715 1.714a1.047 1.047 0 001.48-1.48l-1.714-1.715 7.955-7.955z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Sagittarius;
