export const keyCreator = () => {
  return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
}

export const formatMilitaryTimestamp = (timestamp:string) => {
  if (timestamp === undefined) return "";
  let time = timestamp.split(":")
  var hours = Number(time[0]);
  var minutes = Number(time[1]);
  var seconds = Number(time[2]);

  if (seconds > 30) {
    minutes = minutes + 1
  }

  if (minutes >= 60) {
    let minutesOver = minutes - 60
    hours = hours + 1
    minutes = minutesOver
    if (hours === 24) {
      hours = 0
    }
  }

  var timeValue = "";

  if (hours > 0 && hours <= 12) {
    timeValue= "" + hours;
  } else if (hours > 12) {
    timeValue= "" + (hours - 12);
  } else if (hours === 0) {
    timeValue= "12";
  }

  timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;
  timeValue += (hours >= 12) ? "pm" : "am";

  return timeValue
}

export const formatImportedTimestamp = (timestamp:string) => {
  let time = timestamp.split(":")
  var hours = parseInt(time[0]);
  var minutes = parseInt(time[1]);
  var seconds = parseInt(time[2].substring(0,2));
  var dayNight = time[2].substring(2);

  if (seconds > 30) {
    minutes = minutes + 1
  }

  if (minutes >= 60) {
    let minutesOver = minutes - 60
    hours = hours + 1
    minutes = minutesOver
    if (hours == 13) {
      hours = 1
    }
  }

  if (hours === 0) { hours = 12 }

  var timeValue = String(hours);
  timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;
  timeValue += dayNight

  return timeValue
}

export const militarize = (timestamp:string) => {
  if (!timestamp) { return timestamp }
  let timeParts = timestamp.split(":")
  if (timeParts[timeParts.length - 1].includes("pm")) {
    let newHour = (parseInt(timeParts[0]) === 12) ? 12 : parseInt(timeParts[0]) + 12
    timeParts[0] = newHour.toString()
    let newSecond = timeParts[timeParts.length - 1].replace("pm", "")
    timeParts[timeParts.length - 1] = newSecond
    return timeParts.join(":")
  } else if (timeParts[timeParts.length - 1].includes("am")) {
    let parsedHour = parseInt(timeParts[0])
    let newHour = (parsedHour < 10) ? `0${parsedHour}` : parsedHour.toString();
    timeParts[0] = newHour
    let newSecond = timeParts[timeParts.length - 1].replace("am", "")
    timeParts[timeParts.length - 1] = newSecond
    if (parsedHour === 12) {
      timeParts[0] = "00"
    }
    return timeParts.join(":")
  } else {
    //already in military
    return timestamp
  }
}

export default { keyCreator, formatMilitaryTimestamp, formatImportedTimestamp, militarize}  ;
