import React from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { planets, signs, movements, moons } from '../glyphs/SymbolDictionary';
import { keyCreator } from "../../utils";
import { SvgIcon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    symbolHeader: {
      fontFamily: "Horizon",
      ["@media (max-width:770px)"]: {
        fontSize: "1.33em",
        marginTop: 0,
      }
    },
    tableContainer: {
      maxWidth: "50%",
      marginTop: "30px",
      ["@media (max-width:770px)"]: {
        fontSize: "14px",
        marginTop: '20px'
      },
      ["@media (max-width:320px)"]: {
        fontSize: "12px"
      }
    },
    tableHeader: {
      paddingBottom: "16px"
    },
    closer: {
      background: "none",
      color: "inherit",
      border: "none",
      padding: 0,
      cursor: "pointer",
      "& svg": {
        height: "2em",
        width: "2em"
      }
    }
  })
)

const SymbolIndexContainer = styled('div')({
  position: "relative",
  top: 0,
  width: "100%",
  height: "100%",
  zIndex: 10,
  padding: "70px",
  paddingBottom: "30px",
  background: '#F5FAFF',
  ["@media (max-width:1024px)"]: {
    paddingTop: "10px",
    paddingBottom: 0
  },
  ["@media (max-width:770px)"]: {
    width: "92%",
    padding: "5%",
    paddingTop: 0,
  },
  ["@media (max-width:320px)"]: {
  }
});

const SymbolBox = styled('div')({
  position: "relative",
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  maxHeight: "76%",
  overflowY: "scroll"
})

const GridContainer = styled('div')({
  display: "grid",
  marginBottom: "10px",
  gridTemplateColumns: "38px 200px",
  ["@media (max-width:770px)"]: {
    gridTemplateColumns: "30px 126px",
  }
})


export default function SymbolIndex(props:any) {
  const classes = useStyles();
  const zodiacSigns = Object.keys(signs)
  const plnts = Object.keys(planets)
  const transits = Object.keys(movements)
  const moonEclips = Object.keys(moons)
  const close = props.closeSymbolIndex;

  const translateGeometry = (angle:string) => {
    switch(angle) {
    case "0":
      return "Conjunction"
      break;
    case "60":
      return "Sextile"
      break;
    case "90":
      return "Square"
      break;
    case "120":
      return "Trine"
      break;
    case "180":
      return "Opposition"
      break;
    case "Ingress":
      return angle
      break;
    default:
      return "?"
}
  }

  return (
    <SymbolIndexContainer>
      <button className={classes.closer} onClick={() => close()}><SvgIcon component={CloseIcon} viewBox="0 0 40 40" /></button>
      <h1 className={classes.symbolHeader}>SYMBOL INDEX</h1>
      <SymbolBox>
        <div className={classes.tableContainer}>
          <div className={classes.tableHeader}>SIGNS</div>
          {zodiacSigns && zodiacSigns.map((x:any) =>
            <GridContainer key={keyCreator()}>
              <div key={keyCreator()}>{signs[x]()}</div>
              <div key={keyCreator()}>{x}</div>
            </GridContainer>
          )}
        </div>
        <div className={classes.tableContainer}>
          <div className={classes.tableHeader}>PLANETS</div>
          {plnts && plnts.map((x:any) =>
            <GridContainer key={keyCreator()}>
              <div key={keyCreator()}>{planets[x]()}</div>
              <div key={keyCreator()}>{x}</div>
            </GridContainer>
          )}
        </div>
        <div className={classes.tableContainer}>
          <div className={classes.tableHeader}>ASPECTS</div>
          {transits && transits.map((x:any) =>
            <GridContainer key={keyCreator()}>
              <div key={keyCreator()}>{movements[x]()}</div>
              <div key={keyCreator()}>{translateGeometry(x)}</div>
            </GridContainer>
          )}
          <GridContainer key={keyCreator()}>
            <div key={keyCreator()}>V/C</div>
            <div key={keyCreator()}>Void of Course</div>
          </GridContainer>
          <GridContainer key={keyCreator()}>
            <div key={keyCreator()}>Rx</div>
            <div key={keyCreator()}>Retrograde</div>
          </GridContainer>
          <GridContainer key={keyCreator()}>
            <div key={keyCreator()}>SD</div>
            <div key={keyCreator()}>Station Direct</div>
          </GridContainer>
        </div>
        <div className={classes.tableContainer}>
          <div className={classes.tableHeader}>MOON PHASES & ECLIPSES</div>
          {moonEclips && moonEclips.map((x:any) =>
            <GridContainer key={keyCreator()}>
              <div key={keyCreator()}>{moons[x]()}</div>
              <div key={keyCreator()}>{x}</div>
            </GridContainer>
          )}
        </div>
      </SymbolBox>
    </SymbolIndexContainer>
  )
}
