import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { timeZoneDictionary } from '../../timezoneHandler'


const useStyles = makeStyles((theme) => ({
  timezoneContainer: {
    zIndex: 99,
    position: "absolute",
    left: "30%",
    top: "13px",
    width: "200px",
    display: "inline",
    paddingLeft: "20px",
    "& div.MuiFormControl-root": {
      borderRadius: "6px",
    },
    "& div": {
      fontFamily: 'Circular',
    },
    ["@media (max-width:1440px)"]: {
      left: "33%",
      width: "180px",
    },
    ["@media (max-width:1265px)"]: {
      display: "block",
      left: "0",
      top: "6px",
      position: "relative",
      paddingLeft: "15px"
    },

  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 85,
    border: "2px solid #000000",
    boxSizing: "border-box",
    borderRadius: "13px",
    "& div": {
      fontSize: "13.6587px",
      lineHeight: "17px",
      paddingLeft: "4px",
      ["@media (max-width:1440px)"]: {
        fontSize: "10px",
        lineHeight: "12px"
      }
    },
    ["@media (max-width:1024px)"]: {
      marginLeft: 0,
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function TimeZoneToggle(tzData:any) {
  const classes = useStyles();
  const tzLookup = JSON.parse(JSON.stringify(timeZoneDictionary()));
  const [tz, setTimezone] = useState(tzLookup[tzData.timezone]);
  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    setTimezone(event.target.value);
    tzData.toggleTimeZone(event)
  };

  useEffect(() => { setTimezone(tzLookup[tzData.timezone]) }, [tzData.timezone]);

  return (
    <div className={classes.timezoneContainer}>
      <FormControl className={classes.formControl}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={tz}
          onChange={handleChange}
        >
          <MenuItem value={"Africa/Lagos"}>Africa/Lagos</MenuItem>
          <MenuItem value={"Africa/Maputo"}>Africa/Maputo</MenuItem>
          <MenuItem value={"Africa/Nairobi"}>Africa/Nairobi</MenuItem>
          <MenuItem value={"America/Anchorage"}>America/Anchorage</MenuItem>
          <MenuItem value={"America/Chicago"}>America/Chicago</MenuItem>
          <MenuItem value={"America/Denver"}>America/Denver</MenuItem>
          <MenuItem value={"America/Halifax"}>America/Halifax</MenuItem>
          <MenuItem value={"America/Jamaica"}>America/Jamaica</MenuItem>
          <MenuItem value={"America/La_Paz"}>America/La Paz</MenuItem>
          <MenuItem value={"America/Los_Angeles"}>America/Los Angeles</MenuItem>
          <MenuItem value={"America/New_York"}>America/New York</MenuItem>
          <MenuItem value={"America/Phoenix"}>America/Phoenix</MenuItem>
          <MenuItem value={"America/Sao_Paulo"}>America/Sao Paulo</MenuItem>
          <MenuItem value={"America/St_Johns"}>America/St Johns</MenuItem>
          <MenuItem value={"Asia/Dubai"}>Asia/Dubai</MenuItem>
          <MenuItem value={"Asia/Kolkata"}>Asia/Kolkata</MenuItem>
          <MenuItem value={"Asia/Shanghai"}>Asia/Shanghai</MenuItem>
          <MenuItem value={"Asia/Tokyo"}>Asia/Tokyo</MenuItem>
          <MenuItem value={"Australia/Adelaide"}>Australia/Adelaide</MenuItem>
          <MenuItem value={"Australia/Brisbane"}>Australia/Brisbane</MenuItem>
          <MenuItem value={"Australia/Sydney"}>Australia/Sydney</MenuItem>
          <MenuItem value={"Europe/Athens"}>Europe/Athens</MenuItem>
          <MenuItem value={"Europe/Berlin"}>Europe/Berlin</MenuItem>
          <MenuItem value={"Europe/London"}>Europe/London</MenuItem>
          <MenuItem value={"Pacific/Auckland"}>Pacific/Auckland</MenuItem>
          <MenuItem value={"Pacific/Honolulu"}>Pacific/Honolulu</MenuItem>
          <MenuItem value={"Etc/UTC"}>UTC</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}
