import * as React from "react";

function WaningGibbous(props:any) {
  let height = (props && props.height) ? props.height : 22
  let width = (props && props.width) ? props.width : 22

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width={width}
      height={height}
      style={{
        msTransform: "rotate(360deg)",
        WebkitTransform: "rotate(360deg)",
      }}
      viewBox="0 0 24 24"
      transform="rotate(360)"
      {...props}
    >
      <path
        d="M18 12c0-4.5-1.92-8.74-6-10a10 10 0 000 20c4.08-1.26 6-5.5 6-10z"
        fill="#000000"
      />
      <path fill="rgba(0, 0, 0, 0)" d="M0 0h24v24H0z" />
    </svg>
  );
}

export default WaningGibbous;
