import * as React from "react";

function Saturn(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-saturn"
      role="img"
      {...props}
    >
      <title id="component-svg-saturn">Saturn</title>
      <path
        d="M10 9.28V7h2a1 1 0 000-2h-2V3a1 1 0 10-2 0v2H6a1 1 0 100 2h2v14a1 1 0 002 0v-9.795l.184-.03c.949-.149 1.926-.2 2.818-.1 1.53.173 2.513.752 2.888 1.823.201.577.119 1.368-.24 2.445-.132.397-.288.802-.527 1.381-1.059 2.563-1.197 2.991-.94 3.925.223.817.677 1.427 1.285 1.826.554.363 1.1.49 1.515.497a.95.95 0 10.038-1.9 1.116 1.116 0 01-.51-.186c-.228-.149-.397-.376-.497-.74-.08-.295.084-.808.865-2.696a22.15 22.15 0 00.575-1.508c.478-1.439.6-2.61.23-3.67-.657-1.88-2.287-2.838-4.468-3.085-1.048-.118-2.147-.066-3.216.094z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Saturn;
