import React from "react";
import { useEffect, useState } from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import ReactQuill, { Mixin, Toolbar, Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import firebase from 'firebase/compat/app';
import appLogo from '../../assets/images/logo.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editor: {

    },
    saveButton: {
      marginLeft: "5%",
      border: "1px solid black",
      marginBottom: "5px",
      borderRadius: "6px",
      padding: "0px",
      "& span.MuiButton-label": {
        padding: "6px 8px",
      }
    },
    savedIndicator: {
      float: "right",
      paddingTop: "5px"
    },
    saved: {
      color: "#000"
    },
    saving: {
      color: "#a9a9a9",
      fontStyle: "italic",
      "&::after": {
        position: "relative",
        display: "inline-block",
        content: `url(${appLogo})`,
        marginLeft: "5px",
        top: "5px",
        animation: "$thinking 5s infinite linear"
      }
    },
    yellowGreenBlue: {
      "& :hover": {
        background: "linear-gradient(180deg, #DDF9FB 0%, #EAFFF2 100%)",
      }
    },
    pinkWhite: {
      "& :hover": {
        background: "linear-gradient(to right, rgb(251,203,219) 0%, rgb(255, 252, 254) 100%)",
      }
    },
    blackGray: {
      "& :hover": {
        background: "linear-gradient(19deg, rgb(0, 0, 0) 0%, rgb(115, 115, 115) 100%)",
        color: "white"
      }
    },
    blackBlue: {
      "& :hover": {
        background: "linear-gradient(33deg, rgb(4, 0, 30) 0%, rgb(0, 143, 179) 100%)",
        color: "white"
      }
    },
    yellowPink: {
      "& :hover": {
        background: "linear-gradient(56deg, rgb(248, 240, 120) 0%, rgb(225, 154, 210) 100%)",
      }
    },
    brownPink: {
      "& :hover": {
        background: "linear-gradient(180deg, rgb(203, 134, 76) 0%, rgb(253, 195, 199) 100%)",
      }
    },
    whiteLavender: {
      "& :hover": {
        background: "linear-gradient(180deg, rgb(248, 245, 233) 0%, rgb(245, 233, 248) 100%)"
      }
    },
    blackBrown: {
      "& :hover": {
        background: "linear-gradient(33deg, rgb(4, 0, 30) 0%, rgb(157, 60, 16) 100%)",
        color: "white"
      }
    },
    pinkRed: {
      "& :hover": {
        background: "linear-gradient(56deg, #F1BEC1  0%, #E42929 100%)"
      }
    },
    editableNotes: {
      width: "300px",
      height: "150px",
      overflow: "scroll"
    },
    "@keyframes thinking": {
      "0%": {
        transform: "rotate(0deg)"
      },
      "100%": {
        transform: "rotate(360deg)"
      }
    }
  })
)

const NotesHeader = styled('span')({
  fontWeight: "bold",
  fontSize: "1.5em",
  ["@media (max-width:1200px)"]: {
    fontSize: "1em"
  }
});

const createElementFromSlate = (props:any) => {
  switch (props.type) {
    case 'code':
      return `<p>${props.children.map((c:any)=> c.text).join(" ")}</p>`
    case 'paragraph':
      return `<p>${props.children.map((c:any)=> c.text).join(" ")}</p>`
    default:
      return `<p>${props.children.map((c:any)=> c.text).join(" ")}</p>`
  }
}

export default function NoteEditor(props:any) {
  const noteId = props.existingNoteId;
  const classes = useStyles();
  let fb = firebase.firestore();
  let note = props.existingNote;
  let notesRef = fb.collection("notes")
  const user = props.user;
  const [existingNoteId, setExistingNoteId] = useState(props.existingNoteId)
  const noteType = typeof note
  const [saved, hasFinishedSaving] = useState(true)
  let savedIndicator = (saved) ? "saved" : "saving"
  let savedIndicatorClass = (saved) ? classes.saved : classes.saving
  let reloadNoteIndicator = props.reloadNoteIndicator

  const structuredNote = () => {
    if (existingNoteId && existingNoteId.length) {
      // convert pre-existing objects from slate to html strings
      if (noteType === "object") {
        let htmlString = ""
        note.map((x:any) => {
          htmlString = htmlString.concat(createElementFromSlate(x))
          htmlString += "<br>"
        })
        return htmlString
      } else {
        return (note)
      }
    } else {
      return ""
    }
  }

  const [saveDisabled, toggleSaveDisabled] = useState(true)
  // only save when user stops typing to prevent excessive writes & duplicate objects
  const [debouncedValue, value, setValue] = useDebounce<string>(structuredNote(), 1250);

  const hoverColors = [
    classes.yellowGreenBlue,
    classes.pinkWhite,
    classes.yellowPink,
    classes.whiteLavender,
    classes.blackBlue,
    classes.blackGray,
    classes.brownPink,
    classes.blackBrown,
    classes.pinkRed
  ]

  function useDebounce<T>(
    initialValue: T,
    time: number
  ): [T, T, React.Dispatch<T>] {
    const [value, setValue] = useState<T>(initialValue);
    const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

    useEffect(() => {
      const debounce = setTimeout(() => {
      setDebouncedValue(value);
    }, time);
    return () => {
      clearTimeout(debounce);
    };
    }, [value, time]);

    return [debouncedValue, value, setValue];
  }

  useEffect(() => {
    setExistingNoteId(noteId);
  }, [noteId]);

  useEffect(() => {
    createNewOrUpdateNote()
  },[debouncedValue])

  const createNewOrUpdateNote = () => {
    if (existingNoteId && existingNoteId.length > 0) {
        updateNote();
    } else {
      checkForAutoSaved();
    }
  }

  async function updateNote() {
    await notesRef.doc(existingNoteId)
                  .set({
                    text: value,
                    updatedAt: new Date(),
                  }, { merge: true })
    hasFinishedSaving(true)
  }

  const checkForAutoSaved = () => {
      notesRef.where("userId", "==", user)
              .where("noteDate", "==", props.dateForNote)
              .get()
              .then((querySnapshot) => {
                if (querySnapshot.empty) {
                  if (value.length > 0) {
                    createNote()
                  }
                } else {
                  // date = null
                  querySnapshot.forEach((doc) => {
                    setExistingNoteId(doc.id);
                  });
                }
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              });
  }

  async function createNote() {
    const noteDraft = {
      text: value,
      noteDate: props.dateForNote,
      userId: user,
      createdAt: new Date(),
      updatedAt: new Date()

    };
    if ((!existingNoteId || existingNoteId.length === 0) && value.length > 0) {
      await notesRef.add(noteDraft)
                    .then((docRef) => {
                      setExistingNoteId(docRef.id)
                    })
      hasFinishedSaving(true)
      reloadNoteIndicator()

    }
  }

  const saveNoteCloseEditor = () => {
    createNewOrUpdateNote();
    reloadNoteIndicator();
    props.closeEditModeCallback();
  }

  const handleChange = (noteBody:string, delta:any, source:any, editor:any) => {
    setValue(noteBody)
    hasFinishedSaving(false)
    if (editor.getLength() > 0) {
      toggleSaveDisabled(false)
    }
  }

  return (
    <div className={classes.editor}>
      <NotesHeader>Notes</NotesHeader>
      <Button disabled={saveDisabled}
              className={`${classes.saveButton} ${hoverColors[props.customColor]}`}
              onClick={() => { saveNoteCloseEditor() }}>
        SAVE
      </Button>
      <span className={`${classes.savedIndicator} ${savedIndicatorClass}`}>{savedIndicator}</span>
      <ReactQuill theme="snow"
                  value={value}
                  onChange={(v:string, d:any, source:any, editor:any) => handleChange(v, d, source, editor)}
                  modules={{
                    clipboard: {
                      matchVisual: false,
                    }
                  }}/>
    </div>
  )
}
