import React from 'react';
import { useState } from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, FormHelperText, TextField, Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactHeading: {
      fontFamily: "Horizon",
      ["@media (max-width:770px)"]: {
        margin: 0,
        fontSize: "1em"
      }
    },
    input: {
      marginTop: "30px",
    },
    inputMessage: {
      maxHeight: "60px !important",
      overflow: "scroll !important"
    },
    button: {
      marginTop: "20px",
      background: "linear-gradient(to right, rgba(241,193,193,255) 0%, rgba(245,227,138,255) 100%)"
    },
  })
)

const ContactContainer = styled('div')({
  width: "30vw",
  height: "65%",
  position: 'absolute',
  zIndex: 999,
  top: "14%",
  marginLeft: "35%",
  padding: "40px",
  background: "linear-gradient(to bottom, rgb(241, 190, 193) 0%, rgb(255, 252, 254) 100%)",
  border: "1px solid black",
  borderRadius: "12px",
  textAlign: "center",
  "& > .MuiFormControl-root": {
    width: "70%",
  },
  ["@media (max-width:1024px)"]: {
    width: "96vw",
    height: "65vh",
    marginLeft: "2vw",
    padding: 0,
    paddingTop: "40px"
  },
});

export default function Contact(props:any) {
  const classes = useStyles();
  const [emailValue, setEmailValue] = useState("")
  const [nameValue, setNameValue] = useState("")
  const [messageValue, setMessageValue] = useState("")
  const [submissionState, setSubmissionState] = useState(false)
  const [messageReceived, setMessageReceived] = useState(false)
  const closeContactForm = props.closeContactFormOnSubmit;

  const updateEmailValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmailValue(event.target.value)
  }

  const updateNameValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNameValue(event.target.value)
  }

  const updateMessageValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessageValue(event.target.value)
  }

  const resetForm = () => {
    setEmailValue("")
    setNameValue("")
    setMessageValue("")
    closeContactForm()
  }

  const submitMessage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    if (emailValue.length < 3 || !emailValue.includes("@")) {return} //TODO: proper validation
    setSubmissionState(true)

    var params = {
      service_id: `${process.env.REACT_APP_EMAILJS_SERVICE_ID}`,
      template_id: `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}`,
      user_id: `${process.env.REACT_APP_EMAILJS_USER}`,
      accessToken: `${process.env.REACT_APP_EMAILJS_ACCESS_TOKEN}`,
      template_params: {
        from_name: nameValue,
        from_email: emailValue,
        to_name: "Astrology For Days",
        subject: "Contact Form Submission",
        message: messageValue,
      }
    };

    let url = "https://api.emailjs.com/api/v1.0/email/send"

    fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(params),
    })
    .then(response => {
      // console.log(response.json())
    })
    .catch((error) => {
      console.error('Error:', error);
    });

    resetForm()
  }

  const renderThankYouMessage = () => {
    if (messageReceived) {
      return(<p>Thanks for reaching out! We'll be in touch soon.</p>)
    }
  }

  return (
    <ContactContainer>
      <h1 className={classes.contactHeading}>GET IN TOUCH</h1>
      <FormControl>
        <TextField
          label="Name"
          id="contact-name"
          required
          variant="outlined"
          className={classes.input}
          onChange={(e) => updateNameValue(e)}
        />

        <TextField
          label="Email"
          id="contact-email"
          required
          variant="outlined"
          className={classes.input}
          onChange={(e) => updateEmailValue(e)}
        />
        <FormHelperText id="contact-helper-text">We'll never share your email.</FormHelperText>

        <TextField id="outlined-basic"
                   label="Message"
                   variant="outlined"
                   maxRows={10}
                   multiline
                   className={`${classes.input} ${classes.inputMessage}`}
                   onChange={updateMessageValue}/>
        <Button disabled={submissionState}
                onClick={(e) => submitMessage(e)}
                className={classes.button}>
          SEND
        </Button>
      </FormControl>
      {renderThankYouMessage()}
    </ContactContainer>
  )
}
