import * as React from "react";

function WaningCrescent(props:any) {
  let height = (props && props.height) ? props.height : 22
  let width = (props && props.width) ? props.width : 22
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width={width}
      height={height}
      style={{
        msTransform: "rotate(360deg)",
        WebkitTransform: "rotate(360deg)",
      }}
      viewBox="0 0 24 24"
      transform="rotate(360)"
      {...props}
    >
      <path
        d="M2 12a10 10 0 0013 9.54 10 10 0 010-19.08A10 10 0 002 12z"
        fill="#000000"
      />
    </svg>
  );
}

export default WaningCrescent;
