import * as React from "react";

function Sun(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-sun"
      role="img"
      {...props}
    >
      <title id="component-svg-sun">Sun</title>
      <path
        d="M12 2C6.467 2 2 6.467 2 12s4.467 10 10 10 10-4.467 10-10S17.533 2 12 2zm0 2c4.436 0 8 3.564 8 8s-3.564 8-8 8-8-3.564-8-8 3.564-8 8-8zm0 10a2 2 0 100-4 2 2 0 000 4z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Sun;
