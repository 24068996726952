import * as React from "react";

function Chiron(props:any) {
  return (
    <svg
      height="1em"
      viewBox="0 0 100 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="component-svg-chiron"
      role="img"
      {...props}
    >
      <title id="component-svg-chiron">Chiron</title>
      <path d="M62.123 59.991h12.732L49.972 35.108 75.08 10H62.35L49.426 22.924V10h-9.002v50.506c-8.88 2.043-15.504 9.989-15.504 19.489C24.92 91.044 33.876 100 44.925 100c11.047 0 20.003-8.956 20.003-20.005 0-9.499-6.623-17.446-15.502-19.489V47.293l12.697 12.698zm-6.196 20.004c0 6.066-4.936 11.003-11.002 11.003s-11.003-4.937-11.003-11.003 4.937-11.002 11.003-11.002 11.002 4.936 11.002 11.002z" />
    </svg>
  );
}

export default Chiron;
