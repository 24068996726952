import * as React from "react";

function Moon(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-moon"
      role="img"
      {...props}
    >
      <title id="component-svg-moon">Moon</title>
      <path
        d="M11 4a8 8 0 110 16c-1.861 0-2.96-.597-2.96-.597s.596-.27.824-.396a8.002 8.002 0 00-.2-14.12c-.208-.108-.64-.296-.64-.296S9.14 4 11 4zM5.286 6.007a6 6 0 010 11.986c-.917.043-1.296 1.197-.583 1.776A9.96 9.96 0 0011 22c5.523 0 10-4.477 10-10S16.523 2 11 2a9.96 9.96 0 00-6.297 2.231c-.713.58-.334 1.733.583 1.776z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Moon;
