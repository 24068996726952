import React from 'react';
import { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, RadioGroup, FormControlLabel, Radio, Button, Checkbox } from '@material-ui/core';
import { keyCreator } from "../../utils";
import {loadStripe} from '@stripe/stripe-js';
import firebase from 'firebase/compat/app';
import TermsOfService from '../TermsOfService';
import Spinner from '../Spinner';
import { SvgIcon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subscribeHeader: {
      textAlign: "center",
      fontFamily: "Horizon",
      marginTop: "5%",
      ["@media (max-width:1024px)"]: {
        fontSize: "1.66em"
      },
      ["@media (max-width:770px)"]: {
        fontSize: "1.17em",
        marginBottom: "0px"
      },
      ["@media (max-width:420px)"]: {
        fontSize: "1em"
      }
    },
    formContainer: {
      width: "40%",
      margin: "0 auto",
      "& fieldset": {
        width: "100%"
      },
      "& label": {
        marginTop: "6px"
      },
      ["@media (max-width:1024px)"]: {
        width: "76%"
      },
    },
    goToCheckout: {
      textAlign: "center"
    },
    button: {
      width: "70%",
      margin: "0 auto",
      color: "#009a1f"
    },
    termsCheckbox: {
      marginTop: "30px",
      "& p": {
        textAlign: "center",
        color: "#008fb3",
        cursor: "pointer"
      }
    },
    checkboxLabel: {
      "& span": {
        fontSize: "14px !important"
      }
    },
    subscriptionAnnouncement: {
      marginBottom: "0px",
      textAlign: "center"
    },
    alert: {
      color: "#008fb3",
      marginTop: "5px",
      textAlign: "center"
    },
    closer: {
      background: "none",
      color: "inherit",
      border: "none",
      padding: 0,
      position: "relative",
      left: "20px",
      top: "20px",
      cursor: "pointer",
      "& svg": {
        height: "2em",
        width: "2em"
      }
    },
    termsButton: {
      background: "none",
      color: "inherit",
      border: "none",
      padding: 0,
      fontSize: "1rem",
      fontWeight: "bold"
    }
  })
)

declare global {
  interface Window {
    Rewardful:any;
    _rwq:any
  }
}


export default function SubscriptionSelectScreen(props:any) {
  const classes = useStyles();
  const uid = props.user;
  const [value, setValue] = useState("");
  const [purchaseOptions, setPurchaseOptions] = useState([{}]);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [subscriptionUnderstood, setSubscriptionUnderstood] = useState(true);
  const [readTermsRequest, triggerReadTermsRequest] = useState(false);
  const [spinnerVisible, setSpinnerVisibility] = useState(false)
  var fb = firebase.firestore()
  let stripeOptions:any = [];
  const closer = props.closer;

  const getClientReferenceId = () => {
    return window.Rewardful && window.Rewardful.referral || ('checkout_'+(new Date).getTime());
  }

  const getCouponForReferral = () => {
    return (getClientReferenceId().includes("checkout")) ?  null : process.env.REACT_APP_REWARDFUL_PROMO_CODE
  }

  async function goToCheckout(value:string, uid:string, fb:any) {
    const customerRef = await fb.collection('customers')
        .doc(uid)
        .collection('checkout_sessions')
        .add({
          price: value,
          allow_promotion_codes: true,
          success_url: window.location.origin,
          cancel_url: `${window.location.origin}/${window.location.hash}`,
          metadata: { referral: getClientReferenceId() },
          promotion_code: getCouponForReferral()
        })

      customerRef.onSnapshot(async (snap:any) => {
        const { error, sessionId } = snap.data();
        if (error) {
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          const stripe = await loadStripe(String(process.env.REACT_APP_STRIPE_KEY));
          setSpinnerVisibility(false)
          stripe?.redirectToCheckout({ sessionId });
        }
      });
  }

  useEffect(() => {
    fb.collection('products')
      .where('active', '==', true)
      .get()
      .then((querySnapshot:any) => {
        querySnapshot.forEach(async function (doc:any) {
          const d = doc.data();
          let opt = {"id": doc.id, "description": d.description, "name": d.name, "price": null, "priceKey": null}
          const priceSnap = await doc.ref.collection('prices').get();
          priceSnap.docs.forEach((doc:any) => {
            const p = doc.data();
            opt["price"] = p.unit_amount;
            opt["priceKey"] = doc.id;
            stripeOptions.push(opt);
            updateSubscriptionList(stripeOptions)
            setValue(doc.id)
          })
        });
      })
  },[])

  const updateSubscriptionList = (options:any) => {
    if (options.length >= 2) {
      setPurchaseOptions(options)
    }
  }

  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    setValue(String(event.target.value))
  }

  const formatPrice = (price:number) => {
    let priceStr = String(price).split("")
    priceStr.splice(-2,0,".")
    priceStr.splice(0,0,"$")
    return priceStr.join("")
  }

  const saveTermsAccepted = () => {
    var userPreferenceRef = fb.collection("userPreferences");

    if (uid) {
      userPreferenceRef.doc(uid).set({
        acceptedTermsAndConditionsAt: firebase.firestore.FieldValue.serverTimestamp(),
      }, { merge: true });
    }
  }

  const handleSubscription = () => {
    setSpinnerVisibility(true)
    saveTermsAccepted();
    goToCheckout(value, uid, fb);
  }

  const handleCheckedTerms = (event:React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTerms(event.target.checked);
  };

  const handleSubscriptionUnderstood = (event:React.ChangeEvent<HTMLInputElement>) => {
    setSubscriptionUnderstood(event.target.checked)
  }

  const showTermsAndConditions = () => {
    triggerReadTermsRequest(true)
  }

  const closeReadTermsRequest = () => {
    triggerReadTermsRequest(false)
  }

  const renderTermsOfService = () => {
    return (readTermsRequest) ? <TermsOfService closeSelf={() => closeReadTermsRequest()}/> : <span></span>
  }

  const renderSpinner = () => {
    return (spinnerVisible) ? <Spinner /> : <span></span>
  }

  return (
    <div key={keyCreator()}>
      <button className={classes.closer} onClick={() => closer()}>
        <SvgIcon component={CloseIcon} viewBox="0 0 40 40"/>
      </button>
      <h1 className={classes.subscribeHeader}>Astrology For Days</h1>
      <div className={classes.formContainer} key={keyCreator()}>
        <FormControl component="fieldset" required={true} key={keyCreator()}>
          <p className={classes.subscriptionAnnouncement}>Recurring Subscriptions</p>
          <RadioGroup aria-label="subscription frequency" name="subscription" value={value} onChange={handleChange} key={keyCreator()}>
            {purchaseOptions && purchaseOptions.map((subscription:any) =>
              <FormControlLabel value={subscription.priceKey}
                      control={<Radio value={subscription.priceKey}/>}
                      key={keyCreator()}
                      label={`${formatPrice(subscription.price)} ${subscription.description}`} />
            )}
          </RadioGroup>
          <div className={classes.termsCheckbox}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={subscriptionUnderstood}
                  onChange={handleSubscriptionUnderstood}
                  name="checkedS"
                  color="primary"
                />
              }
              label="I understand that my subscription will auto-renew unless I cancel it before the renewal date."
              className={classes.checkboxLabel}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedTerms}
                  onChange={handleCheckedTerms}
                  name="checkedT"
                  color="primary"
                />
              }
              label="I agree to the terms and conditions."
              className={classes.checkboxLabel}
            />
            <p><button className={classes.termsButton} onClick={() => {showTermsAndConditions()}}>Read Terms and Conditions</button></p>
          </div>
          <Button onClick={() => {handleSubscription()}}
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  disabled={!(checkedTerms && subscriptionUnderstood)}>
            SELECT PLAN
          </Button>
        </FormControl>
      </div>
      {renderTermsOfService()}
      {renderSpinner()}
    </div>
  )
}
