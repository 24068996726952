import React from 'react';
import { useState } from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import DayTransitList from '../../components/DayTransitList/DayTransitList';
import NotesContainer from '../../components/Notes/NotesContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "barViewController": {
      "width": "40%",
      "marginLeft": "15px",
      "borderRadius": "20px",
      "boxShadow": "none",
      ["@media (max-width:1440px)"]: {
        width: "48%",
      },
      ["@media (max-width:1024px)"]: {
        width: "30%",
      },
      ["@media (max-width:770px)"]: {
        width: "52%",
      },
      ["@media (max-width:320px)"]: {
        width: "68%",
      }
    },
    day: {
      position: "relative",
      paddingTop: "1%",
      fontSize: "2.5em",
      ["@media (max-width:1440px)"]: {
        fontSize: "1.33em",
      }
    },
    month: {
      fontWeight: "bold",
    }
  })
);

const MoonDay = styled('div')({
  display: 'flex',
  width: '90%',
  padding: '10px'
});

export default function ViewControlBar(day:any) {
  const classes = useStyles();
  let transits = day.transits.transits;
  let voc = day.transits.voc;
  let ingress = day.transits.ingress;
  let rxRd = day.transits.rxRd;
  let gradient = day.transits.customColor;

  const handleChange = (event:any, newValue:number) => {
    setValue(newValue)
    convertValToStr(newValue.toString())
  }

  const dayString = () => {
    return `${day.transits.year}/${day.transits.month}/${day.transits.day}`
  }
  const selectedDate = dayString()

  const [value, setValue] = useState(0);
  const [strValue, convertValToStr] = useState(value.toString())

  return (
    <div id="tab-bar-view-control">
    <TabContext value={strValue} >
      <AppBar position="static" className={classes.barViewController}>
        <Tabs value={value}
              onChange={(event, newValue) => handleChange(event,newValue)}
              aria-label="notes and transit list toggle view"
              variant="fullWidth">
          <Tab label="&#128302;" />
          <Tab label="&#128221;" />
        </Tabs>
      </AppBar>
      <TabPanel value="0" >
        <DayTransitList day={day.day}
                        month={day.month}
                        transits={transits}
                        voc={voc}
                        ingress={ingress}
                        rxRd={rxRd}
                        inCurrentMonth={true}
                        small={false}/>
      </TabPanel>
      <TabPanel value="1">
        <NotesContainer key={selectedDate}
                        selectedDate={selectedDate}
                        customColor={gradient}
                        reloadNoteIndicators={day.reloadNoteIndicators}/>
      </TabPanel>
    </TabContext>
    </div>
  )
}
