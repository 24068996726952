import * as React from "react";

function Leo(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-leo"
      role="img"
      {...props}
    >
      <title id="component-svg-leo">Leo</title>
      <path
        d="M12 15.5a4.5 4.5 0 11-4.168-4.488C5.812 6.032 7.929 2 13.6 2c7.636 0 8.846 5.825 3.38 13.675-1.202 1.727-1.221 3.119-.463 3.866.683.673 1.87.682 2.845-.131a1 1 0 011.282 1.535c-1.743 1.455-4.094 1.438-5.531.02-1.524-1.502-1.49-3.97.226-6.433C20.022 7.807 19.231 4 13.6 4c-4.826 0-5.987 3.67-2.631 8.633A4.482 4.482 0 0112 15.5zm-2.623-1.651a2.5 2.5 0 10.251.339 1.007 1.007 0 01-.1-.123c-.052-.072-.102-.144-.151-.216z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Leo;
