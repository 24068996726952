import React from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import firebase from 'firebase/compat/app';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      gridTemplateColumns: "repeat(6, 50px)",
      gridGap: "10px",
      margin: "0 auto",
      ["@media (max-width:770px)"]: {
        gridTemplateColumns: "repeat(6, 46px)"
      },
      ["@media (max-width:320px)"]: {
        gridTemplateColumns: "repeat(6, 40px)",
      },
      ["@media (max-width:280px)"]: {
        gridTemplateColumns: "repeat(6, 32px)",
      }
    },
    greenBlueYellow: {
      background: "linear-gradient(to right, rgba(250,255,0,0.15) 0%, rgba(74,255,147,0.15) 40%, rgba(117,222,255,0.15) 100%)"
    },
    pinkWhite: {
      background: "linear-gradient(to right, rgb(251,203,219) 0%, rgb(255, 252, 254) 100%)"
    },
    yellowPink: {
      background: "linear-gradient(56deg, rgb(248, 240, 120) 0%, rgb(225, 154, 210) 100%)"
    },
    pinkRed: {
      background: "linear-gradient(56deg, #F1BEC1  0%, #E42929 100%)"
    },
    blackBlue: {
      background: "linear-gradient(33deg, rgb(4, 0, 30) 0%, rgb(0, 143, 179) 100%)"
    },
    blackGray: {
      background: "linear-gradient(33deg, rgb(0, 0, 0) 0%, rgb(115, 115, 115) 100%)"
    },
    whiteLavender: {
      background: "linear-gradient(180deg, rgb(248, 245, 233) 0%, rgb(245, 233, 248) 100%)"
    },
    brownPink: {
      background: "linear-gradient(33deg, rgb(203, 134, 76) 0%, rgb(253, 195, 199) 100%)"
    },
    blackBrown: {
      background: "linear-gradient(33deg, rgb(4, 0, 30) 0%, rgb(157, 60, 16) 100%)"
    }
  })
);

const ColorOption = styled('button')({
  height: "50px",
  borderRadius: "50%",
  border: "1px solid black",
  cursor: "pointer",
  ["@media (max-width:770px)"]: {
    height: "46px",
  },
  ["@media (max-width:320px)"]: {
    height: "40px",
  },
  ["@media (max-width:280px)"]: {
    height: "32px",
  }
});

export default function ColorMenu(props:any) {
  const classes = useStyles();
  const showBox = props.visibility ? "grid" : "none";
  const bgChange = props.backgroundChange
  var fb = firebase.firestore()
  var userPreferenceRef = fb.collection("userPreferences");

  const selectCustomColor = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const customTheme = event.currentTarget.value

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        userPreferenceRef.doc(user.uid).set({
          customThemeBackground: customTheme,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
      }
    })
    bgChange(event.currentTarget.value)
  }

  return (
    <Box className={classes.gridContainer} position="relative" display={showBox} height={80} p={2}>
      <ColorOption onClick={(e) => selectCustomColor(e)} value="0" className={classes.greenBlueYellow}></ColorOption>
      <ColorOption onClick={(e) => selectCustomColor(e)} value="1" className={classes.pinkWhite}></ColorOption>
      <ColorOption onClick={(e) => selectCustomColor(e)} value="2" className={classes.yellowPink}></ColorOption>
      <ColorOption onClick={(e) => selectCustomColor(e)} value="3" className={classes.whiteLavender}></ColorOption>
      <ColorOption onClick={(e) => selectCustomColor(e)} value="8" className={classes.pinkRed}></ColorOption>
      <ColorOption onClick={(e) => selectCustomColor(e)} value="4" className={classes.blackBlue}></ColorOption>
      <ColorOption onClick={(e) => selectCustomColor(e)} value="5" className={classes.blackGray}></ColorOption>
      <ColorOption onClick={(e) => selectCustomColor(e)} value="6" className={classes.brownPink}></ColorOption>
      <ColorOption onClick={(e) => selectCustomColor(e)} value="7" className={classes.blackBrown}></ColorOption>
    </Box>
  )
}

