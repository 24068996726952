import * as React from "react";

function Neptune(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-neptune"
      role="img"
      {...props}
    >
      <title id="component-svg-neptune">Neptune</title>
      <path
        d="M13 12.945V16h3.005c.55 0 .995.444.995 1 0 .552-.456 1-.995 1H13v3.005a1.001 1.001 0 01-2 0V18H7.995A.995.995 0 017 17c0-.552.456-1 .995-1H11v-3.055C6.5 12.448 3 8.633 3 4a1 1 0 112 0 7.002 7.002 0 006 6.93V3.994a1.001 1.001 0 012 0v6.934A7.002 7.002 0 0019 4a1 1 0 012 0c0 4.633-3.5 8.448-8 8.945z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Neptune;
