import React from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PhasedMoon from '../../components/PhasedMoon/PhasedMoon'
import ViewControlBar from '../../components/ViewControlBar/ViewControlBar'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayDetailsContainer: {
      marginTop: '20%',
      color: "#1E1E1E",
      ["@media (max-width:1440px)"]: {
        padding: "8%",
        marginTop: "15%"
      },
      ["@media (max-width:1024px)"]: {
        marginTop: "0",
        paddingTop: "2%",
        paddingBottom: "15%"
      },
      ["@media (max-width:680px)"]: {
        paddingLeft: "3%"
      }
    },
    day: {
      position: "relative",
      paddingTop: "1%",
      fontSize: "2.25em",
      ["@media (max-width:1440px)"]: {
        fontSize: "1.33em",
      }
    },
    month: {
      fontWeight: "bold",
    },
    moonTranslation: {
      paddingLeft: "20px",
      color: "#009a1f",
      paddingBottom: "20px",
      fontWeight: "bold"
    }
  })
);

const MoonDay = styled('div')({
  display: 'flex',
  width: '90%',
  padding: '10px 0px 0px 10px'
});


export default function DetailedDay(day:any) {
  const classes = useStyles();
  let moonPhase = day.moonPhase;

  const detectSign = () => {
    var sign = ""
    for (let t of day.transits) {
      if (t.planet === "Moon" && t.details.aspect.id === "Sun") {
        if (t.details.aspect.asp === 180 || t.details.aspect.asp === 0) {
          sign = t.details.sign
          break
        }
      }
    }
    return sign
  }

  const renderMoonPhase = () => {
    if (!day.small && [0,4,8,9].includes(moonPhase)) {
      let sign = detectSign()
      let event = ""
      let phase = Math.floor(moonPhase)
      if (phase === 0) {event = "New Moon"}
      if (phase === 4) {event = "Full Moon"}
      if (phase === 8) {event = "Lunar Eclipse"}
      if (phase === 9) {event = "Solar Eclipse"}
      return (<div className={classes.moonTranslation}>{`${event} in ${sign}`}</div>)
    } else {
      return (<span></span>)
    }
  }

  return (
    <div className={classes.dayDetailsContainer}>
      <MoonDay>
        <PhasedMoon moonPhase={moonPhase}/>
        <div className={classes.day}>
          <span className={classes.month}>{day.monthAsString}</span> {day.day}  {day.dayOfWeek}
        </div>
      </MoonDay>
      {renderMoonPhase()}
      <ViewControlBar transits={day} reloadNoteIndicators={day.reloadNoteIndicators}/>
    </div>
  )
}
