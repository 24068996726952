import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Aquarius from '../glyphs/Aquarius';
import Aries from '../glyphs/Aries';
import Cancer from '../glyphs/Cancer';
import Capricorn from '../glyphs/Capricorn';
import Chiron from '../glyphs/Chiron';
import Conjunction from '../glyphs/Conjunction';
import Gemini from '../glyphs/Gemini';
import Ingress from '../glyphs/Ingress'
import Jupiter from '../glyphs/Jupiter'
import Leo from '../glyphs/Leo'
import Libra from '../glyphs/Libra'
import Mars from '../glyphs/Mars'
import Mercury from '../glyphs/Mercury'
import Moon from '../glyphs/Moon'
import Neptune from '../glyphs/Neptune'
import NorthNode from '../glyphs/NorthNode'
import Opposition from '../glyphs/Opposition'
import Pisces from '../glyphs/Pisces'
import Pluto from '../glyphs/Pluto'
import Sagittarius from '../glyphs/Sagittarius'
import Saturn from '../glyphs/Saturn'
import Scorpio from '../glyphs/Scorpio'
import Sextile from '../glyphs/Sextile'
import SouthNode from '../glyphs/SouthNode'
import Square from '../glyphs/Square'
import Sun from '../glyphs/Sun'
import Taurus from '../glyphs/Taurus'
import Trine from '../glyphs/Trine'
import Uranus from '../glyphs/Uranus'
import Venus from '../glyphs/Venus'
import Virgo from '../glyphs/Virgo'
import { keyCreator, formatMilitaryTimestamp, militarize } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    transitList: {
      padding: "10px",
      color: "black",
      display: "flex",
      flexDirection: "column"
    },
    transit: {
      display: "flex",
      verticalAlign: "middle",
    },
    largeList: {
      fontSize: "20px"
    },
    smallList: {
      position: "absolute",
      bottom: 0,
      right: 0,
      ["@media (max-width:770px)"]: {
        display: "none",
        '&::before': {

        }
      }
    },
    transitSmall: {
      marginTop: "0px",
      fontSize: "0.75rem",
      lineHeight: "10px",
      "& span": {
        marginLeft: "0px"
      },
      ["@media (max-width:1440px)"]: {
        fontSize: "0.5rem",
        lineHeight: "7px",
        marginBottom: "0px"
      },
      ["@media (max-width:1280px)"]: {
        "& svg": {
           height: "10px"
        }
      },
      ["@media (max-width:1024px)"]: {
        "& svg": {
           height: "12px"
        }
      }
    },
    smallTimestamp: {
      marginLeft: "3px !important"
    },
    transitLarge: {
      display: "grid",
      gridColumnGap: "5px",
      marginBottom: "0px",
      marginTop: "5px",
      fontSize: "1em",
      ["@media (max-width:1200px)"]: {
        fontSize: ".8em"
      },
      ["@media (max-width:1024px)"]: {
        fontSize: "1.33em"
      },
      ["@media (max-width:770px)"]: {
        fontSize: "1em",
      },
      ["@media (max-width:400px)"]: {
        fontSize: ".75em"
      },
      ["@media (max-width:300px)"]: {
        fontSize: ".66em"
      }
    },
    glyphContainer: {
      display: "inline-block",
      "& svg": {
        verticalAlign: "middle"
      },
      "& span": {
        verticalAlign: "middle"
      }
    },
    event: {
      marginLeft: "-6px",
      textAlign: "center",
    },
    transitWithPosition: {
      gridTemplateColumns: "5% 20% 5% 5% 20% 45%",
      alignItems: "center",
      ["@media (max-width:1440px)"]: {
        gridTemplateColumns: "5% 25% 5% 5% 25% 35%",
      },
      ["@media (max-width:1350px)"]: {
        gridTemplateColumns: "7% 28% 5% 6% 28% 26%",
      },
      ["@media (max-width:1024px)"]: {
        gridTemplateColumns: "4% 16% 4% 4% 16% 56%",
      },
      ["@media (max-width:770px)"]: {
        gridTemplateColumns: "4% 20% 3% 3% 20% 45%"
      },
      ["@media (max-width:680px)"]: {
        gridTemplateColumns: "5% 20% 5% 5% 20% 45%",
      },
      ["@media (max-width:480px)"]: {
        gridTemplateColumns: "6% 30% 6% 6% 30% 22%",
      },
      ["@media (max-width:320px)"]: {
        gridTemplateColumns: "10% 30% 5% 9% 30% 19%"
      }
    },
    transitNoPosition: {
      gridTemplateColumns: "5% 5% 5% 20% 20% 45%",
      ["@media (max-width:1440px)"]: {
        gridTemplateColumns: "5% 6% 5% 24% 25% 35%",
      },
      ["@media (max-width:1350px)"]: {
        gridTemplateColumns: "8% 7% 6% 22% 23% 35%",
      },
      ["@media (max-width:1024px)"]: {
        gridTemplateColumns: "4% 5% 4% 3% 4% 80%",
      },
      ["@media (max-width:770px)"]: {
        gridTemplateColumns: "4% 4% 4% 4% 4% 82%",
      },
      ["@media (max-width:680px)"]: {
        gridTemplateColumns: "5% 7% 5% 5% 28% 28%",
      },
      ["@media (max-width:400px)"]: {
        gridTemplateColumns: "6% 7% 5% 4% 4% 82%"
      }
    },
    tabGlyphSize: {
      height: "30px",
      width: "30px",
      ["@media (max-width:1440px)"]: {
        height: "24px",
        width: "24px",
      }
    },
    textListItem: {
      marginLeft: "5px"
    },
    rxTextListItem: {
      marginLeft: "0px"
    },
    positionContainer: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    degreePosition: {
      display: "inline-block",
      marginLeft: "0px !important",
      "& span": {
        verticalAlign: "middle"
      }
    },
    signContainer: {
      display: "inline-block",
      "& svg": {
        verticalAlign: "middle",
      }
    },
    timestamp: {
      marginLeft: "3px",
      fontWeight: "normal",
      color: 'dimgray',
      ["@media (max-width:1440px)"]: {
        marginLeft: "1px !important",
      }
    },
    singlePlanetTs: {
      marginLeft: "15px !important"
    },
    vocTs: {
      marginLeft: "0px",
    },
    ingressTs: {
      marginLeft: "10px"
    },
    greenText: {
      color: "#009a1f",
      fill: "#009a1f"
    },
    blueText: {
      color: "#008fb3",
      fill: "#008fb3",
      fontWeight: "bold"
    },
    redText: {
      color: "#b40035",
      fill: "#b40035",
      fontWeight: "bold"
    },
    blackText: {
      color: "black",
      fill: "black"
    },
    whiteText: {
      color: "white",
      fill: "white"
    }
  })
);


const symbols: { [key: string]: any } = {
  "Aquarius": Aquarius,
  "Aries": Aries,
  "Cancer": Cancer,
  "Capricorn": Capricorn,
  "Chiron": Chiron,
  "0": Conjunction,
  "Gemini": Gemini,
  "Ingress": Ingress,
  "Jupiter": Jupiter,
  "Leo": Leo,
  "Libra": Libra,
  "Mars": Mars,
  "Mercury": Mercury,
  "Moon": Moon,
  "Neptune":  Neptune,
  "North Node": NorthNode,
  "180": Opposition,
  "Pisces": Pisces,
  "Pluto": Pluto,
  "Sagittarius": Sagittarius,
  "Saturn": Saturn,
  "Scorpio": Scorpio,
  "60": Sextile,
  "90": Square,
  "South Node": SouthNode,
  "Sun": Sun,
  "Taurus": Taurus,
  "120": Trine,
  "Uranus": Uranus,
  "Venus": Venus,
  "Virgo": Virgo
};

const importedAbbreviations: { [key: string]: any } = {
  "Aq": Aquarius,
  "Ar": Aries,
  "Cn": Cancer,
  "Cp": Capricorn,
  "Ge": Gemini,
  "Le": Leo,
  "Li": Libra,
  "Pi": Pisces,
  "Sg": Sagittarius,
  "Sc": Scorpio,
  "Ta": Taurus,
  "Vi": Virgo
}

const orderedSigns = ["Aries", "Taurus", "Gemini", "Cancer", "Leo", "Virgo", "Libra", "Scorpio", "Sagittarius", "Capricorn", "Aquarius", "Pisces"]


export default function DayTransitList(day:any) {
  const classes = useStyles();
  let voc = day.voc
  let ingress = day.ingress
  let vocIngress = Array.prototype.concat(voc,ingress).filter((n:any) => n)
  let transits = day.transits || []
  let rxRd = day.rxRd;
  let customColor = Math.floor(day.customColor);
  let transitList = (<div></div>);
  let cn = classes.transitSmall;
  let lcn = classes.smallList;
  let timestampClss = classes.smallTimestamp;
  let tabClass="";

  if (!day.small) {
    cn = classes.transitLarge;
    lcn = classes.largeList;
    tabClass = classes.tabGlyphSize;
    timestampClss = classes.timestamp;
  }

  const renderGlyph = (x:string, size:boolean, tabClass:string, stringClass:string, textColor:string) => {
    if (!x) {return}
    const classed = (size) ? "12" : "30";
    if (x === "D") x = "SD"
    if (x == "R") x = "Rx"
    const TagName = symbols[x]
    if (TagName) {
      return <TagName height={classed} width={classed} className={tabClass}/>;
    } else {
      return <span className={stringClass}>{x}</span>
    }
  }

  const renderMinutes = (small:boolean, degree:string) => {
    if (small) {return}
    let strMin:string = "";
  }

  const renderPosition = (small:boolean, degree:string, sign:string) => {
    if (small) {return}
    let strSign:string = "";
    let dgr:string = "";
    let strMin:string = "";
    if (degree.includes(":")) {
      let degreeParts = degree.split(":")
      dgr = degreeParts[0].substring(1)
      let intDgr = parseInt(dgr)
      let min = parseInt(degreeParts[1])
      let secs = parseInt(degreeParts[2])
      let minute = (secs > 30) ? min + 1 : min
      if (minute === 60) {
        minute = 0
        intDgr += 1
        if (intDgr === 30) {
          intDgr = 0
          sign = orderedSigns[orderedSigns.indexOf(sign) + 1]
        }
      }
      strMin = (minute < 10) ? `0${minute}` : `${minute}`
      dgr = (intDgr < 10) ? `0${intDgr}` : `${intDgr}`
    } else { // for spreadsheet imports --> rx/rd
      let degreeParts = degree.split("\u00b0")
      dgr = degreeParts[0]
      strMin = degreeParts[1].slice(2,4);
      strSign = degreeParts[1].slice(0, 2);
    }

    let TagName = (symbols[sign]) ? symbols[sign] : importedAbbreviations[strSign]
    if (TagName) {
      return (<span className={`${classes.positionContainer}`}>
          <span className={classes.degreePosition}><span>{dgr}&#176;</span></span><span className={classes.signContainer}><TagName height={24} width={24} className={tabClass}/></span><span className={classes.degreePosition}><span>{strMin}&#8242;</span></span>
        </span>
      )
    } else {
      return (<span></span>)
    }
  }

  const positionTimestamp = (e:string, ts:string) => {
    let timestamp = formatMilitaryTimestamp(ts)
    let clss = ""
    if (e === "V/C") {
      clss = classes.vocTs
    } else if (e === "\u2192") {
      clss = classes.ingressTs
    } else {
      clss = classes.singlePlanetTs
    }
    return <span className={clss}>{timestamp}</span>
  }

  const sortedEvents = (allEvents:Array<any>) => {
    if (allEvents.length <= 1) { return allEvents }

    return allEvents.sort((a:any, b:any) => {
      try {
        return (militarize(a.details.timestamp).localeCompare(militarize(b.details.timestamp)))
      }
      catch(err) {
        return a
      }

    })
  }

  // let chronoEvents = sortedEvents(transits.concat(vocIngress, rxRd).filter((n:any) => n))
  let chronoEvents = sortedEvents(Array.prototype.concat(transits, voc, ingress, rxRd).filter((n:any) => n))

  const colorCode = (planet:string, small:boolean) => {
    if (planet === "Moon" && !small) {
      return classes.greenText;
    } else if (small && [4,5,6,7].includes(customColor)) {
      return classes.whiteText
    } else {
      return classes.blackText;
    }
  }

  const ordered = (transit:any) => {
    return chronoEvents.indexOf(transit)
  }

  if (day.inCurrentMonth && chronoEvents.length > 0) {
    transitList = (
      <div className={`${classes.transitList} ${lcn}`}>
        {transits && transits.map((x:any) =>
          <div className={`${classes.transit} ${cn} ${colorCode(x.planet, day.small)} ${classes.transitWithPosition}`}
               key={x.details.aspect.orb}
               onClick={day.clickParent}
               style={{order: ordered(x)}}>
           <span className={`${classes.glyphContainer}`}>{renderGlyph(x.planet, day.small, tabClass, classes.textListItem, colorCode(x.planet, day.small))}</span>
           {renderPosition(day.small, x.details.planet_degree, x.details.sign)}
           <span className={`${classes.glyphContainer} ${classes.event}`}>{renderGlyph(String(x.details.aspect.asp), day.small, tabClass, classes.textListItem, colorCode(x.planet, day.small))}</span>
           <span className={`${classes.glyphContainer}`}>{renderGlyph(x.details.aspect.id, day.small, tabClass, classes.textListItem, colorCode(x.planet, day.small))}</span>
           {renderPosition(day.small, x.details.object_degree, x.details.object_sign)}
           <span className={timestampClss}>{formatMilitaryTimestamp(x.details.timestamp)}</span>
          </div>)
        }
        {vocIngress && vocIngress.map((s:any) =>
          <div className={`${classes.transit} ${cn} ${classes.blueText} ${classes.transitNoPosition}`}
               key={keyCreator()}
               onClick={day.clickParent}
               style={{order: ordered(s)}}>
            <span className={`${classes.glyphContainer}`}>{renderGlyph(s.planet, false, tabClass, classes.textListItem, classes.blueText)}</span>
            <span className={`${classes.glyphContainer}`}>{renderGlyph(s.details.event, false, tabClass, classes.textListItem, classes.blueText)}</span>
            <span className={`${classes.glyphContainer}`}>{renderGlyph(s.details.ingressSign, false, tabClass, classes.textListItem, classes.blueText)}</span>
            <span className={`${timestampClss}`}>{positionTimestamp(s.details.event, s.details.timestamp)}</span>
          </div>)
        }
        {rxRd && rxRd.map((s:any) =>
          <div className={`${classes.transit} ${cn} ${classes.redText} ${classes.transitWithPosition}`}
               key={keyCreator()}
               onClick={day.clickParent}
               style={{order: ordered(s)}}>
            <span className={`${classes.glyphContainer}`}>{renderGlyph(s.planet, false, tabClass, classes.textListItem, classes.redText)}</span>
            {renderPosition(day.small, s.details.degree, "")}
            {renderGlyph(s.details.direction, false, tabClass, classes.rxTextListItem, classes.redText)}
            <span className={`${classes.singlePlanetTs} ${timestampClss}`}>{formatMilitaryTimestamp(s.details.timestamp)}</span>
          </div>)
        }
      </div>
    )
  }

  return (
    <Box pr={2} display="block" key={keyCreator()}>{transitList}</Box>
  )
}
