import * as React from "react";

function NorthNode(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.001 512.001"
      width="1em"
      height="1em"
      aria-labelledby="component-svg-north-node"
      role="img"
      {...props}
    >
      <title id="component-svg-north-node">North Node</title>
      <path d="M480 224C480 100.297 379.719 0 256 0S32 100.297 32 224c0 77.594 39.468 145.969 99.406 186.156-18.406 8.422-31.188 26.969-31.188 48.516 0 29.454 23.875 53.328 53.344 53.328 29.438 0 53.328-23.874 53.328-53.328 0-21.656-12.922-40.281-31.453-48.641-23.469-10.172-44.562-24.531-62.75-42.719-18.625-18.625-33.219-40.297-43.438-64.422-10.561-24.984-15.905-51.515-15.905-78.89 0-27.359 5.344-53.906 15.906-78.875 10.219-24.141 24.812-45.813 43.438-64.422C131.313 62.078 153 47.454 177.125 37.25c24.969-10.562 51.5-15.906 78.875-15.906s53.906 5.344 78.875 15.906c24.126 10.204 45.812 24.828 64.438 43.454 18.625 18.609 33.219 40.281 43.438 64.422 10.562 24.968 15.906 51.516 15.906 78.875 0 27.375-5.344 53.906-15.906 78.891-10.219 24.125-24.812 45.797-43.438 64.422-18.188 18.188-39.281 32.578-62.733 42.734-18.517 8.375-31.423 26.985-31.423 48.625 0 29.454 23.891 53.328 53.344 53.328s53.344-23.874 53.344-53.328c0-21.562-12.812-40.124-31.219-48.531C440.547 369.938 480 301.594 480 224zM185.563 458.672c0 17.641-14.359 32-32 32-17.656 0-32-14.359-32-32s14.344-32 32-32c17.64 0 32 14.359 32 32zm204.939 0c0 17.641-14.36 32-32.001 32s-32-14.359-32-32 14.359-32 32-32 32.001 14.359 32.001 32z" />
    </svg>
  );
}

export default NorthNode;
