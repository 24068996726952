import * as React from "react";

function Cancer(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-cancer"
      role="img"
      {...props}
    >
      <title id="component-svg-cancer">Cancer</title>
      <path
        d="M4.88 3.841c.055-.061.119-.117.191-.165C9.923.45 16.956 2.134 20.715 6.57a1 1 0 01-1.526 1.293c-2.155-2.543-5.612-4.01-8.84-3.846a4.5 4.5 0 11-5.47-.176zm8.772 16.142a4.5 4.5 0 115.47.176c-.055.061-.119.117-.191.165-4.853 3.226-11.885 1.542-15.644-2.894a1 1 0 111.526-1.293c2.155 2.543 5.612 4.01 8.84 3.846zM7.5 10a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm9 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Cancer;
