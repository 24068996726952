import * as React from "react";

function Virgo(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-virgo"
      role="img"
      {...props}
    >
      <title id="component-svg-virgo">Virgo</title>
      <path
        d="M19.8 11.6c0 2.556-1.049 4.382-2.8 5.366V10.4c0-.768.632-1.4 1.4-1.4.768 0 1.4.632 1.4 1.4v1.2zM17 7.311v-.61C17 4.663 15.46 3 13.5 3c-.988 0-1.869.422-2.5 1.102A3.393 3.393 0 008.5 3a3.396 3.396 0 00-2.529 1.134A3.773 3.773 0 003.73 3.008a1.001 1.001 0 00-.258 1.984A1.725 1.725 0 015.001 6.7v13.1a1 1 0 002 0V6.684C7.007 5.728 7.685 5 8.5 5c.818 0 1.5.736 1.5 1.7v13.1a1 1 0 002 0V6.7c0-.964.68-1.7 1.5-1.7.818 0 1.5.736 1.5 1.7v10.966a7.826 7.826 0 01-1.008.125 1 1 0 10.092 1.998c.312-.014.616-.045.915-.086V21a1 1 0 002 0v-1.81c2.938-1.139 4.8-3.826 4.8-7.59v-1.2c0-1.872-1.528-3.4-3.4-3.4-.5 0-.972.115-1.4.311z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Virgo;
