import React from 'react';
import { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Note from './Note'
import NoteEditor from './NoteEditor'
import firebase from 'firebase/compat/app';
import { keyCreator } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxContainer: {
      width: "23vw",
      ["@media (max-width:1024px)"]: {
        width: "80vw"
      },
    }
  })
)

export default function NotesContainer(props:any) {
  const classes = useStyles();
  let fb = firebase.firestore();
  let start = new Date(props.selectedDate);
  start.setHours(0,0,0,0);
  let end = new Date(props.selectedDate);
  end.setHours(23,59,59,999);
  const [currentUserId, setCurrentUserId] = useState("");
  const [note, setNote] = useState("")
  const [dateForNote, setNoteDate] = useState(new Date(props.selectedDate).setHours(12,0,0,0));
  const [editMode, toggleEditMode] = useState(true);
  const [existingNoteId, setNoteId] = useState("");
  let triggerNoteIndicatorReload = props.reloadNoteIndicators;
  firebase.auth().onAuthStateChanged((user:any) => {
    setCurrentUserId(user.uid)
  })

  const getExistingNote = () => {
    fb.collection("notes").where("userId", "==", currentUserId)
      .where("noteDate", ">=", start.getTime())
      .where("noteDate", "<=", end.getTime())
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          setNote("")
          toggleEditMode(true)
        } else {
          if (querySnapshot.docs.length === 1) {
            let d = querySnapshot.docs[0]
            let text = d.data().text
            setNoteId(d.id)
            setNote(text)
            if (text.length > 0 && text !== "<p><br></p>") {
              toggleEditMode(false)
            }
          } else if (querySnapshot.docs.length > 1) {
            let maxUpdatedAt = Math.max.apply(Math, querySnapshot.docs.map(function(d) { return d.data().updatedAt.seconds; }))
            let doc = querySnapshot.docs.find(function(m){return m.data().updatedAt.seconds === maxUpdatedAt})
            if (doc) {
              setNoteId(doc.id)
              setNote(doc.data().text)
              toggleEditMode(false)
            }
          }
        }
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }

  useEffect(() => {
    getExistingNote();
  },[currentUserId])

  useEffect(() => {
    getExistingNote();
  },[dateForNote])

  useEffect(() => {
    if (existingNoteId && existingNoteId.length) {
      fb.collection("notes").doc(existingNoteId)
      .onSnapshot((doc) => {
        // var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        setNote(doc.data()?.text)
      });
    } else {
      getExistingNote();
    }
  },[editMode])

  const callEditMode = () => {
    toggleEditMode(true)
  }

  const closeEditMode = () => {
    toggleEditMode(false);
    triggerNoteIndicatorReload();
  }

  const reloadNoteIndicators = () => {
    triggerNoteIndicatorReload()
  }

  const renderNote = () => {
    if (editMode ){
      return (
        <NoteEditor user={currentUserId}
                    dateForNote={dateForNote}
                    existingNote={note}
                    existingNoteId={existingNoteId}
                    closeEditModeCallback={closeEditMode}
                    customColor={props.customColor}
                    reloadNoteIndicator={reloadNoteIndicators}
                    />
      )
    } else {
      return (<Note text={note}
                    clickFunction={() => callEditMode()}
                    customColor={props.customColor}
                    key={keyCreator()}
                    />)
    }
  }

  return (
    <Box position="relative" height="100%" className={classes.boxContainer} padding="15px" >
      {renderNote()}
    </Box>
  )
}

