import * as React from "react";

function Mars(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-mars"
      role="img"
      {...props}
    >
      <title id="component-svg-mars">Mars</title>
      <path
        d="M10 20a6 6 0 110-12 6 6 0 010 12M21 2h-6.667a1 1 0 000 2h4.253l-3.68 3.68a8 8 0 101.414 1.414L20 5.414v4.253a1 1 0 002 0V3a1 1 0 00-1-1"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Mars;
