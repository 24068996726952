import * as React from "react";

function Aquarius(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-aquarius"
      role="img"
      {...props}
    >
      <title id="component-svg-aquarius">Aquarius</title>
      <path
        d="M5.746 8.387l1.143 1.235c1.124 1.213 2.943 1.213 4-.031l1.115-1.195 1.135 1.226c1.124 1.213 2.943 1.213 4-.031l1.122-1.187 2.128 2.298c.36.389.954.398 1.326.021.372-.377.381-.999.02-1.388l-2.812-3.037a.908.908 0 00-1.346 0l-1.813 1.957c-.346.407-.902.407-1.278 0l-1.813-1.957a.908.908 0 00-1.346 0L9.514 8.255c-.346.407-.902.407-1.278 0L6.423 6.298a.908.908 0 00-1.346 0L2.264 9.335c-.36.39-.35 1.01.02 1.388a.908.908 0 001.327-.021l2.135-2.315zm0 7l1.143 1.235c1.124 1.213 2.943 1.213 4-.031l1.115-1.195 1.135 1.226c1.124 1.213 2.943 1.213 4-.031l1.122-1.187 2.128 2.298c.36.389.954.398 1.326.021.372-.377.381-.999.02-1.388l-2.812-3.037a.908.908 0 00-1.346 0l-1.813 1.957c-.346.407-.902.407-1.278 0l-1.813-1.957a.908.908 0 00-1.346 0l-1.813 1.957c-.346.407-.902.407-1.278 0l-1.813-1.957a.908.908 0 00-1.346 0l-2.813 3.037c-.36.39-.35 1.01.02 1.388a.908.908 0 001.327-.021l2.135-2.315z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Aquarius;
