import * as React from "react";

function Trine(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      aria-labelledby="component-svg-trine trine-definition"
      role="img"
      {...props}
    >
      <title id="component-svg-trine">Trine</title>
      <desc id="trine-definition">A 120 degree angle between objects.</desc>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path fill="none" opacity={0.3} d="M0 0h24v24H0z" />
          <path d="M20.37 18l-7.5-13a1.06 1.06 0 00-1.74 0l-7.5 13a1 1 0 000 1 1 1 0 00.87.5h15a1 1 0 00.87-.5 1 1 0 000-1zm-14.14-.5L12 7.5l5.77 10z" />
        </g>
      </g>
    </svg>
  );
}

export default Trine;
