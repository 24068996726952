import * as React from "react";

function Pisces(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-pisces"
      role="img"
      {...props}
    >
      <title id="component-svg-pisces">Pisces</title>
      <path
        d="M12.901 11c.26-3.754 1.986-7.343 5.178-8.899a1 1 0 01.876 1.798C16.498 5.096 15.149 7.966 14.906 11h4.277a1 1 0 110 2h-4.277c.243 3.035 1.592 5.904 4.049 7.101a1 1 0 11-.876 1.798C14.887 20.344 13.16 16.755 12.9 13h-2.769c-.26 3.755-1.985 7.344-5.177 8.9a1 1 0 11-.876-1.799c2.456-1.197 3.806-4.066 4.048-7.1H3.85a1 1 0 010-2h4.277c-.242-3.035-1.592-5.905-4.048-7.102a1 1 0 01.876-1.798c3.192 1.556 4.918 5.145 5.177 8.9h2.77z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Pisces;
