import * as React from "react";

function Jupiter(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      aria-labelledby="component-svg-jupiter"
      role="img"
      {...props}
    >
      <title id="component-svg-jupiter">Jupiter</title>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path fill="none" opacity={0.3} d="M0 0h24v24H0z" />
          <path d="M21 16h-5v-5a1 1 0 00-2 0v5H7.72a8.16 8.16 0 00-.21-13.65A1 1 0 006.44 4a6.16 6.16 0 01-.23 10.54l-2.7 1.55s-.06.06-.1.08l-.14.12-.08.12a1 1 0 00-.16.39v.1c0 .03-.03.1-.03.1a.47.47 0 000 .17v.09a.67.67 0 00.09.21l.07.1a1.14 1.14 0 00.15.16l.12.08a.7.7 0 00.17.09.94.94 0 00.4.1h10v3a1 1 0 002 0v-3h5a1 1 0 000-2z" />
        </g>
      </g>
    </svg>
  );
}

export default Jupiter;
