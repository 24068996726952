import {  zonedTimeToUtc, utcToZonedTime, toDate, format } from 'date-fns-tz'

// lookup allows for backward compatability for removals/edits/deprecations
// from IANA or our internal refactoring
export const timeZoneDictionary = () => {
  const tzLookup: { [key: string]: string } = {
    "Africa/Lagos": "Africa/Lagos",               // +01:00 +01:00
    "Africa/Maputo": "Africa/Maputo",             // +2:00  +2:00
    'Africa/Nairobi': "Africa/Nairobi",           // +03:00 +03:00
    "America/Anchorage": "America/Anchorage",     // −09:00 −08:00
    "America/Chicago": "America/Chicago",         // −06:00 −05:00
    "America/Denver": "America/Denver",           // −07:00  −06:00
    "America/Halifax": "America/Halifax",         // −04:00 −03:00
    "America/Jamaica": "America/Jamaica",         // −05:00 −05:00
    "America/La_Paz": "America/La_Paz",           // −04:00 −04:00
    "America/Los_Angeles": "America/Los_Angeles", // −08:00 −07:00
    "America/New_York": "America/New_York",       // −05:00 −04:00
    "America/Phoenix": "America/Phoenix",         // −07:00  −07:00
    "America/Sao_Paulo": "America/Sao_Paulo",     // −03:00 −03:00
    "America/St_Johns": "America/St_Johns",       // −03:30  −02:30
    "Asia/Dubai": "Asia/Dubai",                   // +04:00 +04:00
    "Asia/Kolkata": "Asia/Kolkata",               // +05:30 +05:30
    "Asia/Shanghai": "Asia/Shanghai",             // +08:00 +08:00
    "Asia/Tokyo": "Asia/Tokyo",                   // +09:00 +09:00
    "Australia/Adelaide": "Australia/Adelaide",   // +09:30 +10:30
    "Australia/Brisbane": "Australia/Brisbane",   // +10:00 +10:00
    "Australia/Perth": "Australia/Perth",         // +08:00 +08:00
    "Australia/Sydney": "Australia/Sydney",       // +10:00  +11:00
    "et": "America/New_York",
    "Europe/Athens": "Europe/Athens",             // +02:00 +03:00
    "Europe/Berlin": "Europe/Berlin",             // +01:00 +02:00
    "Europe/London": "Europe/London",             // +00:00 +01:00
    "Pacific/Auckland": "Pacific/Auckland",       // +12:00  +13:00
    "Pacific/Honolulu": "Pacific/Honolulu",       // −10:00 −10:00
    "pt": "America/Los_Angeles",
    "utc": "Etc/UTC",                             // +00:00 +00:00
    "Etc/UTC": "Etc/UTC"
  }

  return tzLookup
}

export const detectLocalTz = () => {
  let localTz = Intl.DateTimeFormat().resolvedOptions().timeZone
  let defaultTz = timeZoneDictionary()[localTz] ? localTz : null
  return defaultTz
}

export const lastDayOfMonth = (year:number, month:number) => {
  return new Date(year, month, 0).getDate();
}

const previousMonth = (month:number) => {
  let prevMonth = month-1
  if (prevMonth === 0) {
    prevMonth = 12
  }
  return prevMonth
}

export const earliestTimestampInZonedMonth = (year:number, month:number, preferredTimeZone:string) => {
  const prevMonth = previousMonth(month)
  if (prevMonth === 12) {
    year -= 1
  }
  const prevMonthDays = lastDayOfMonth(year, prevMonth)
  const monthString = (prevMonth < 10 ) ? `0${prevMonth}` : prevMonth
  const d = `${year}-${monthString}-${prevMonthDays} 23:59:31` // 30 seconds earlier than first moment of the month due to rounding
  const earliestMomentDateInZone = toDate(d, { timeZone: preferredTimeZone })
  return earliestMomentDateInZone.getTime()
}

export const latestTimestampInZonedMonth = (year:number, month:number, lastDay:number, preferredTimeZone:string) => {
  const monthString = (month < 10 ) ? `0${month}` : month
  const d = `${year}-${monthString}-${lastDay} 23:59:30` // 30 seconds earlier than actual end due to rounding --> compensate on earliest tz above
  const latestMomentDateInZone = toDate(d, { timeZone: preferredTimeZone })
  return latestMomentDateInZone.getTime()
}

export const getZonedDateTime = (timestamp: number, tz:string) => {
  let zoned =  utcToZonedTime(timestamp, tz)
  let zonedString = format(zoned, 'yyyy-MM-dd HH:mm:ss XXX', { timeZone: tz })
  return zonedString
}

export default { earliestTimestampInZonedMonth, latestTimestampInZonedMonth, getZonedDateTime };
