import * as React from "react";

function Mercury(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      aria-labelledby="component-svg-mercury"
      role="img"
      {...props}
    >
      <title id="component-svg-mercury">Mercury</title>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path fill="none" opacity={0.3} d="M0 0h24v24H0z" />
          <path d="M7 4.14a6.84 6.84 0 001.56 1.15A6 6 0 0011 16.1v1.75H9.2a1 1 0 000 2H11v1.27a1 1 0 102 0v-1.27h2a1 1 0 000-2h-2V16.1a6 6 0 002.44-10.81A6.84 6.84 0 0017 4.14a7.15 7.15 0 001.51-2.23A1 1 0 0017.94.6a1 1 0 00-1.31.53 5.21 5.21 0 01-1.08 1.59A4.81 4.81 0 0114 3.8a5.07 5.07 0 01-3.9 0 4.81 4.81 0 01-1.6-1.08 5.21 5.21 0 01-1.13-1.59A1 1 0 006.06.6a1 1 0 00-.53 1.31A7.15 7.15 0 007 4.14zm5 10.05a4 4 0 114-4 4 4 0 01-4 4z" />
        </g>
      </g>
    </svg>
  );
}

export default Mercury;
