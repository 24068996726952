import React from 'react';
import SvgLogo from './components/Logo'
import './App.css';
import { useEffect, useState, useLayoutEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import HomePage from './components/HomePage/HomePage'
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Calendar, Day } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DayTransitList from './components/DayTransitList/DayTransitList';
import TimeZoneToggle from './components/TimeZoneToggle/TimeZoneToggle';
import DetailedDay from './components/DetailedDay/DetailedDay';
import transitStore from './data/utc-transits.json'
import moons from './data/moon-phases-all-zones.json';
import eclipseStore from './data/eclipses.json';
import vocStore from './data/voc.json';
import ingressStore from'./data/ingress.json';
import rxRdStore from './data/retrograde-direct.json';
import FirstQuarter from './assets/images/moons/FirstQuarter.svg';
import FirstQuarterWhite from './assets/images/moons/FirstQuarterWhite.svg';
import FullMoon from './assets/images/moons/FullMoon.svg';
import FullMoonWhite from './assets/images/moons/FullMoonWhite.svg';
import LastQuarter from './assets/images/moons/LastQuarter.svg';
import LastQuarterWhite from './assets/images/moons/LastQuarterWhite.svg';
import NewMoon from './assets/images/moons/NewMoon.svg';
import NewMoonWhite from './assets/images/moons/NewMoonWhite.svg';
import SolarEclipse from './assets/images/moons/SolarEclipse.svg';
import SolarEclipseWhite from './assets/images/moons/SolarEclipseWhite.svg';
import LunarEclipse from './assets/images/moons/LunarEclipse.svg';
import LunarEclipseWhite from './assets/images/moons/LunarEclipseWhite.svg';
import BottomNav from './components/BottomNav/BottomNav';
import SubscriptionSelectScreen from './components/SubscriptionSelect/SubscriptionSelectScreen';
import Spinner from './components/Spinner';
import SymbolIndex from './components/SymbolIndex/SymbolIndex';
import Ephemeris from './components/Ephemeris/Ephemeris';
import Faq from './components/HomePage/Faq'
import UserGuide from './components/UserGuide/UserGuide';
import { keyCreator } from "./utils";
import {
  timeZoneDictionary,
  detectLocalTz,
  earliestTimestampInZonedMonth,
  latestTimestampInZonedMonth,
  getZonedDateTime } from './timezoneHandler'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendar: {
    },
    dayContainer: {
      position: "relative",
      border: "solid white 1px",
      backgroundRepeat: "no-repeat",
    },
    noteIndicatorDot: {
      position: "absolute",
      top: "6px",
      right: "6px",
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      backgroundColor: "#b40035",
      ["@media (max-width:320px)"]: {
        top: "3px",
        right: "3px",
        height: "8px",
        width: "8px",
      }
    },
    lightText: {
      "& p": {
        color: "white",
      }
    },
    inspiration: {
      paddingRight: "20px",
      textAlign: "right",
      "& a": {
        color: "#008fb3"
      },
      ["@media (max-width:1265px)"]: {
        position: "relative",
        bottom: "55px"
      },
      ["@media (max-width:1024px)"]: {
        bottom: "5px",
        fontSize: "12px"
      },
      ["@media (max-width:320px)"]: {
        bottom: "15%",
        paddingRight: "10px"
      },
      ["@media (max-width:280px)"]: {
        bottom: "26%"
      }
    },
    newMoon: {
      backgroundImage: `url(${NewMoon})`,
      backgroundPosition: "2px 2px",
      "& span.MuiIconButton-label": {
        visibility: "hidden"
      },
      ["@media (max-width:1220px)"]: {
        backgroundSize: "25%",
      },
      ["@media (max-width:770px)"]: {
        backgroundPosition: "center",
        backgroundSize: "70%"
      }
    },
    newMoonWhite: {
      backgroundImage: `url(${NewMoonWhite})`,
      backgroundPosition: "2px 2px",
      color: "#B0EDEF",
      "& span.MuiIconButton-label": {
        visibility: "hidden"
      },
      ["@media (max-width:1220px)"]: {
        backgroundSize: "25%",
      },
      ["@media (max-width:770px)"]: {
        backgroundPosition: "center",
        backgroundSize: "70%"
      }
    },
    firstQuarter: {
      backgroundImage: `url(${FirstQuarter})`,
      backgroundPosition: "2px 2px",
      "& span.MuiIconButton-label": {
        visibility: "hidden"
      },
      ["@media (max-width:1220px)"]: {
        backgroundSize: "25%",
      },
      ["@media (max-width:770px)"]: {
        backgroundPosition: "center",
        backgroundSize: "70%"
      }
    },
    firstQuarterWhite: {
      backgroundImage: `url(${FirstQuarterWhite})`,
      backgroundPosition: "2px 2px",
      "& span.MuiIconButton-label": {
        visibility: "hidden"
      },
      ["@media (max-width:1220px)"]: {
        backgroundSize: "25%",
      },
      ["@media (max-width:770px)"]: {
        backgroundPosition: "center",
        backgroundSize: "70%"
      }
    },
    fullMoon: {
      backgroundImage: `url(${FullMoon})`,
      backgroundPosition: "2px 2px",
      "& span.MuiIconButton-label": {
        visibility: "hidden"
      },
      ["@media (max-width:1220px)"]: {
        backgroundSize: "25%",
      },
      ["@media (max-width:770px)"]: {
        backgroundPosition: "center",
        backgroundSize: "70%",
      }
    },
    fullMoonWhite: {
      backgroundImage: `url(${FullMoonWhite})`,
      backgroundPosition: "2px 2px",
      "& span.MuiIconButton-label": {
        visibility: "hidden"
      },
      ["@media (max-width:1220px)"]: {
        backgroundSize: "25%",
      },
      ["@media (max-width:770px)"]: {
        backgroundPosition: "center",
        backgroundSize: "70%",
      }
    },
    lastQuarter: {
      backgroundImage: `url(${LastQuarter})`,
      backgroundPosition: "2px 2px",
      "& span.MuiIconButton-label": {
        visibility: "hidden"
      },
      ["@media (max-width:1220px)"]: {
        backgroundSize: "25%",
      },
      ["@media (max-width:770px)"]: {
        backgroundPosition: "center",
        backgroundSize: "70%"
      }
    },
    lastQuarterWhite: {
      backgroundImage: `url(${LastQuarterWhite})`,
      backgroundPosition: "2px 2px",
      "& span.MuiIconButton-label": {
        visibility: "hidden"
      },
      ["@media (max-width:1220px)"]: {
        backgroundSize: "25%",
      },
      ["@media (max-width:770px)"]: {
        backgroundPosition: "center",
        backgroundSize: "70%"
      }
    },
    solarEclipse: {
      backgroundImage: `url(${SolarEclipse})`,
      backgroundPosition: "2px 2px",
      "& span.MuiIconButton-label": {
        visibility: "hidden"
      },
      ["@media (max-width:1220px)"]: {
        backgroundSize: "25%",
      },
      ["@media (max-width:770px)"]: {
        backgroundPosition: "center",
        backgroundSize: "70%"
      }
    },
    solarEclipseWhite: {
      backgroundImage: `url(${SolarEclipseWhite})`,
      backgroundPosition: "2px 2px",
      "& span.MuiIconButton-label": {
        visibility: "hidden"
      },
      ["@media (max-width:1220px)"]: {
        backgroundSize: "25%",
      },
      ["@media (max-width:770px)"]: {
        backgroundPosition: "center",
        backgroundSize: "70%"
      }
    },
    lunarEclipse: {
      backgroundImage: `url(${LunarEclipse})`,
      backgroundPosition: "2px 2px",
      "& span.MuiIconButton-label": {
        visibility: "hidden"
      },
      ["@media (max-width:1220px)"]: {
        backgroundSize: "25%",
      },
      ["@media (max-width:770px)"]: {
        backgroundPosition: "center",
        backgroundSize: "70%"
      }
    },
    lunarEclipseWhite: {
      backgroundImage: `url(${LunarEclipseWhite})`,
      backgroundPosition: "2px 2px",
      "& span.MuiIconButton-label": {
        visibility: "hidden"
      },
      ["@media (max-width:1220px)"]: {
        backgroundSize: "25%",
      },
      ["@media (max-width:770px)"]: {
        backgroundPosition: "center",
        backgroundSize: "70%"
      }
    },
  })
);

const Container = styled('div')({
  position: "fixed",
  display: "flex",
  top: 0,
  height: '100vh',
  width: '100vw',
  ["@media (max-width:1024px)"]: {
    position: 'static',
    display: 'block'
  }
});

const LogoContainer = styled('div')({
  position: "absolute",
  top: 0,
  width: '100%',
  cursor: "pointer",
  ["@media (max-width:1024px)"]: {
    textAlign: 'right'
  }
});

const RightPanel = styled('div')({
  position: "fixed",
  top: 0,
  right: 0,
  height: '100vh',
  width: '66vw',
  paddingTop: "1%",
  background: '#F5FAFF',
  ["@media (max-width:1024px)"]: {
    position: 'static',
    display: 'block',
    width: '100vw',
    height: 'unset',
    marginTop: "12%"
  },
  ["@media (max-width:770px)"]: {
    marginTop: "25%",
    paddingTop: "12px"
  },
  ["@media (max-width:320px)"]: {
    marginTop: "33%",
    height: '70%',
  },
  ["@media (max-width:280px)"]: {
    height: "58%"
  }
});

const LeftPanel = styled('div')({
  position: "fixed",
  top: "12vh",
  left: 0,
  height: '88vh',
  width: '33vw',
  padding: '10px 0 0 10px',
  ["@media (max-width:1024px)"]: {
    position: 'static',
    display: 'block',
    width: '100vw',
    height: 'unset',
    padding: '0'
  }
});

declare global {
  interface Window {
    A4D:any;
  }
}


function App() {
  type timezoneConvertedTransits = {
      [key: string]: any
  }
  let tzInitialObj:timezoneConvertedTransits = {}
  let tzVocInitial:timezoneConvertedTransits = {}
  let tzIngressInitial:timezoneConvertedTransits = {}
  window.A4D = {}
  const classes = useStyles();
  const moonsObj = JSON.parse(JSON.stringify(moons))
  const eclipses = JSON.parse(JSON.stringify(eclipseStore))
  // const vocIngress = JSON.parse(JSON.stringify(vocIngressStore))
  const voc = JSON.parse(JSON.stringify(vocStore))
  const ingress = JSON.parse(JSON.stringify(ingressStore))
  const rxRd = JSON.parse(JSON.stringify(rxRdStore))
  const tzLookup = JSON.parse(JSON.stringify(timeZoneDictionary()));
  const monthObj = JSON.parse(JSON.stringify(transitStore));

  const [selectedDate, handleDateChange] = useState(new Date());
  const [timezone, setTimeZone] = useState(window.A4D.tz || detectLocalTz() || "pt");
  const [tzTransitStore, setTzTransitStore] = useState(tzInitialObj) // {<year>: {<month>: "days": <day>: []}} array index is day date
  const [tzIngressStore, setTzIngressStore] = useState(tzIngressInitial)
  const [tzVocStore, setTzVocStore] = useState(tzVocInitial)
  const [customBackground, setCustomBackground] = useState(3);
  const [isSignedIn, toggleAuth] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(new Date())
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(new Date())
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState("");
  const [currentMonthInView, setCurrentMonthInView] = useState(new Date())
  const [spinnerVisible, setSpinnerVisibility] = useState(false);
  const [symbolIndexMode, setSymbolIndexMode] = useState(false);
  const [ephemerisMode, setEphemerisMode] = useState(false);
  const [faqMode, setFaqMode] = useState(false)
  const [userGuideMode, setUserGuideMode] = useState(false)
  const [homepageMode, setHomepageMode] = useState(false)
  const [noteCollection, setAllNotes] = useState(new Array<number>())
  const [timezonesConvertedForView, addTimezonesToConverted] = useState(["ETC/UTC"])
  const colors = ["greenBlueYellow", "pinkWhite", "yellowPink", "whiteLavender", "blackBlue", "blackGray",  "brownPink", "blackBrown", "pinkRed"]

  firebase.auth().onAuthStateChanged((user:any) => {
    storeUserData(user);
  })

  const storeUserData = (user:any) => {
    toggleAuth(!!user);
    if (user !== null) {
      setCurrentUserId(user.uid)
    }
  }

  const analytics = firebase.analytics();
  var fb = firebase.firestore()
  var userPreferenceRef = fb.collection("userPreferences");
  var customerRef = fb.collection("customers")
  var notesRef = fb.collection("notes")

  const cacheTimeZone = () => {
    window.A4D.tz = timezone
  }

  const getSubscriptionInfo = () => {
    setSpinnerVisibility(true)
    if (currentUserId) {
      const custSubscriptions = customerRef.doc(currentUserId)
        .collection('subscriptions')
        .where("status", "==", "active")
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            setHasActiveSubscription(false)
            setSpinnerVisibility(false)
          } else {
            setHasActiveSubscription(true)
            querySnapshot.forEach((doc) => {
              let subscriptionLength = doc.data().role
              let endDate = doc.data().current_period_end.toDate()
              if (doc.data().role === "yearlyExtended") {
                endDate = new Date(endDate.getFullYear(), 11, 31)
              }
              if (currentUserId === "2cyXuVOvbvhMkAWqrBYXSKQr5BL2") {
                endDate = new Date(2025, 11, 31)
              }
              let startDate = doc.data().current_period_start.toDate()
              setSubscriptionEndDate(endDate)
              setSubscriptionStartDate(startDate)
              setSubscriptionPeriod(subscriptionLength)
              setSpinnerVisibility(false)
            })
          }
        })
        .catch((error) => {
          setSpinnerVisibility(false)
          console.log("Error getting documents: ", error);
        });
    }
  }

  const getMonthNoteDates = () => {
    let y = currentMonthInView.getFullYear();
    let m = currentMonthInView.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 1);
    var daysWithNotes = new Array<number>();
    if (currentUserId) {
      notesRef.where("userId", "==", currentUserId)
        .where("noteDate", ">", firstDay.getTime())
        .where("noteDate", "<" , lastDay.getTime())
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            // console.log("no notes")
          } else {
            querySnapshot.forEach((doc) => {
              let d = doc.data();
              let date = new Date(d.noteDate);
              let text = d.text
              if (text.length > 0 && text !== "<p><br></p>") {
                daysWithNotes.push(date.getDate());
              }
            });
          }
        })
        .then((d) => {
          setAllNotes(daysWithNotes)
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  }

  const linkCalHeaderToEphemeris = () => {
    let header = document.querySelector(".MuiPickersCalendarHeader-transitionContainer > p")
    if (header) {
      header.addEventListener("click", function() {
        setEphemerisMode(true);
      })
    }

  }

  const monthInZoneCalculated = () => {
    let tz = tzLookup[timezone]
    if (!timezonesConvertedForView.includes(tz)) {return false}
    let y = parseCalYear(currentMonthInView)
    if (!tzTransitStore[y]) {return false}
    if (!tzTransitStore[y][tz]) {return false}
    let monthInTz = tzTransitStore[y][tz][parseCalMonth(currentMonthInView)]
    if (!monthInTz) {return false}
    return true
  }

  useEffect(() => {
    const syncUserPreferences = () => {
      cacheTimeZone();
      if (currentUserId) {
        userPreferenceRef.doc(currentUserId).onSnapshot(function(doc) {
          let item = doc.data();
          if (item && item.timezone !== undefined) {
            let tz = item?.timezone;
            setTimeZone(tz);
          }
          if (item && item.customThemeBackground !== undefined) {
            let bg = item?.customThemeBackground ? item?.customThemeBackground : 3
            setCustomBackground(bg);
          } else {
            setCustomBackground(3)
          }
        });
      }
    }
    syncUserPreferences();
  },[timezone, currentUserId])

  useEffect(() => {
    getSubscriptionInfo();
  }, [currentUserId])

  useEffect(() => {
    if (monthInZoneCalculated()) {
      // console.log("already calculated")
      return
    }else {
      transitsForTimezone();
    }
  }, [timezone])

  useEffect(() => {
    if (monthInZoneCalculated())  {
      return
    }else {
      transitsForTimezone();
    }
  }, [currentMonthInView])

  useEffect(() => {
    getMonthNoteDates()
  }, [currentUserId])

  useEffect(() => {
    getMonthNoteDates()
  }, [currentMonthInView])

  useEffect(() => {
    linkCalHeaderToEphemeris();
  },[currentMonthInView])

  useEffect(() => {
    linkCalHeaderToEphemeris();
  },[hasActiveSubscription])

  useEffect(() => {
    linkCalHeaderToEphemeris();
  },[symbolIndexMode])

  useEffect(() => {
    linkCalHeaderToEphemeris();
  },[ephemerisMode])

  useLayoutEffect(() => {
    let selectedCalClass = `calendar__${colors[customBackground]}`;
    let selectedBtnClass = `button__${colors[customBackground]}`;
    let calObserver = new MutationObserver(() => {
       const calBg = document.querySelector(".MuiPickersSlideTransition-transitionContainer > div:first-child")
       if (calBg) {
          calBg.classList.remove(...calBg.classList);
          calBg?.classList.add(selectedCalClass)
       }
    });
    calObserver.observe(document, {subtree: true, childList: true});
    let tabBtnObserver = new MutationObserver(() => {
       const btnBg = document.querySelector("#tab-bar-view-control button.Mui-selected")
       if (btnBg) {
          let btnClsses = btnBg.classList
          let classArray = colors.map((c) => { return `button__${c}`})
          btnClsses.remove(...classArray);
          btnBg?.classList.add(selectedBtnClass)
       }
    });
    tabBtnObserver.observe(document, {subtree: true, childList: true});

    return () => {
      if (calObserver) {calObserver.disconnect()}
      if (tabBtnObserver) {tabBtnObserver.disconnect()}
    }
  },[customBackground])

  const handleTimeZoneChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    let tz = event.target.value;
    if (tz !== timezone) {
      window.A4D.tz = tz
      setTimeZone(String(tz));
      saveTimezoneToDatabase(String(tz))
    }
  }

  const saveTimezoneToDatabase = (tz:string) => {
    firebase.auth().onAuthStateChanged(function(user:any) {
      if (user) {
        userPreferenceRef.doc(user.uid).set({
          timezone: tz,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
      }
    })
  }

  const ingressForTimezone = (start:number, end:number, yearKey:string, monthKey:string, startDay:string, startYear:string, endYear:string , startMonth:string , endMonth:string) => {
    let endDay = "1";
    let tz = tzLookup[timezone];
    let zonedIngress = tzIngressStore;
    if (!zonedIngress[yearKey]) {zonedIngress[yearKey] = {}}
    if (!zonedIngress[yearKey][tz]) {zonedIngress[yearKey][tz] = {}}
    if (!zonedIngress[yearKey][tz][monthKey]) {zonedIngress[yearKey][tz][monthKey] = {}}
    if (!zonedIngress[yearKey][tz][monthKey]["days"]) {zonedIngress[yearKey][tz][monthKey]["days"] = {}}

    Object.keys(ingress[yearKey]["utc"][monthKey]["days"]).forEach((key:string) => {
      if (!zonedIngress[yearKey][tz][monthKey]["days"][key]) {zonedIngress[yearKey][tz][monthKey]["days"][key] = []}
      ingress[yearKey]["utc"][monthKey]["days"][key].forEach((transit:any) => {
        let zonedTransit = convertTransitTime(transit, tz, start, end)

        if (zonedTransit.day) {
          let convertedDay = zonedTransit.day
          let convertedTransit = zonedTransit.transit
          if (zonedIngress[yearKey][tz][monthKey]["days"][convertedDay]) {
            zonedIngress[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          } else  {
            zonedIngress[yearKey][tz][monthKey]["days"][convertedDay] = [] //converted day may be future key
            zonedIngress[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          }
        }
      })

    })

    if (ingress[startYear] && ingress[startYear]["utc"][startMonth] && ingress[startYear]["utc"][startMonth]["days"][startDay]) {
      ingress[startYear]["utc"][startMonth]["days"][startDay].forEach((transit:any) => {
        let zonedTransit = convertTransitTime(transit, tz, start, end)
        if (zonedTransit.day) {
          let convertedDay = zonedTransit.day
          let convertedTransit = zonedTransit.transit
          if (zonedIngress[yearKey][tz][monthKey]["days"][convertedDay] != null) {
            zonedIngress[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          } else {
            zonedIngress[yearKey][tz][monthKey]["days"][convertedDay] = []
            zonedIngress[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          }
        }
      })
    }

    if (ingress[endYear] && ingress[endYear]["utc"] && ingress[endYear]["utc"][endMonth] && ingress[endYear]["utc"][endMonth]["days"][endDay]) {
      ingress[endYear]["utc"][endMonth]["days"][endDay].forEach((transit:any) => {
        let zonedTransit = convertTransitTime(transit, tz, start, end)
        if (zonedTransit.day) {
          let convertedDay = zonedTransit.day
          let convertedTransit = zonedTransit.transit
          if (zonedIngress[yearKey][tz][monthKey]["days"][convertedDay] != null) {
            zonedIngress[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          } else {
            zonedIngress[yearKey][tz][monthKey]["days"][convertedDay] = []
            zonedIngress[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          }
        }
      })
    }
    setTzIngressStore(zonedIngress)
  }

  const vocForTimezone = (start:number, end:number, yearKey:string, monthKey:string, startDay:string, startYear:string, endYear:string , startMonth:string , endMonth:string) => {
    let endDay = "1";
    let tz = tzLookup[timezone];
    let zonedVoc = tzVocStore;
    if (!zonedVoc[yearKey]) {zonedVoc[yearKey] = {}}
    if (!zonedVoc[yearKey][tz]) {zonedVoc[yearKey][tz] = {}}
    if (!zonedVoc[yearKey][tz][monthKey]) {zonedVoc[yearKey][tz][monthKey] = {}}
    if (!zonedVoc[yearKey][tz][monthKey]["days"]) {zonedVoc[yearKey][tz][monthKey]["days"] = {}}

    Object.keys(voc[yearKey]["utc"][monthKey]["days"]).forEach((key:string) => {
      if (!zonedVoc[yearKey][tz][monthKey]["days"][key]) {zonedVoc[yearKey][tz][monthKey]["days"][key] = []}
      voc[yearKey]["utc"][monthKey]["days"][key].forEach((transit:any) => {
        let zonedTransit = convertTransitTime(transit, tz, start, end)

        if (zonedTransit.day) {
          let convertedDay = zonedTransit.day
          let convertedTransit = zonedTransit.transit
          if (zonedVoc[yearKey][tz][monthKey]["days"][convertedDay]) {
            zonedVoc[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          } else  {
            zonedVoc[yearKey][tz][monthKey]["days"][convertedDay] = [] //converted day may be future key
            zonedVoc[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          }
        }
      })

    })

    if (voc[endYear] && voc[endYear]["utc"] && voc[endYear]["utc"][endMonth] && voc[startYear]["utc"][startMonth]["days"][startDay]) {
      voc[startYear]["utc"][startMonth]["days"][startDay].forEach((transit:any) => {
        let zonedTransit = convertTransitTime(transit, tz, start, end)
        if (zonedTransit.day) {
          let convertedDay = zonedTransit.day
          let convertedTransit = zonedTransit.transit
          if (zonedVoc[yearKey][tz][monthKey]["days"][convertedDay] != null) {
            zonedVoc[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          } else {
            zonedVoc[yearKey][tz][monthKey]["days"][convertedDay] = []
            zonedVoc[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          }
        }
      })
    }

    if (voc[endYear] && voc[endYear]["utc"] && voc[endYear]["utc"][endMonth] && voc[endYear]["utc"][endMonth]["days"][endDay]) {
      voc[endYear]["utc"][endMonth]["days"][endDay].forEach((transit:any) => {
        let zonedTransit = convertTransitTime(transit, tz, start, end)
        if (zonedTransit.day) {
          let convertedDay = zonedTransit.day
          let convertedTransit = zonedTransit.transit
          if (zonedVoc[yearKey][tz][monthKey]["days"][convertedDay] != null) {
            zonedVoc[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          } else {
            zonedVoc[yearKey][tz][monthKey]["days"][convertedDay] = []
            zonedVoc[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          }
        }
      })
    }
    setTzVocStore(zonedVoc)
  }
  // TODO: Cleanup this monstrosity & those above
  // create a "DayHelper" module that calculates transit lists ?
  const transitsForTimezone = () => {
    // Selected timezone may not always be local time.
    // Date/time will be in local system timezone but just looking for months,
    // global day range, & years to parse
    // Pull all transits from last day of prev month to first day of next month
    // in order to cover all possible utc offsets.

    let year = parseCalYear(currentMonthInView)
    let month = parseCalMonth(currentMonthInView)
    let daysInCurrentMonth = new Date(year, month, 0).getDate();
    let lastMonthDateObj = new Date(currentMonthInView.getTime());
    let nextMonthDateObj = new Date(currentMonthInView.getTime());
    lastMonthDateObj.setDate(1)
    lastMonthDateObj.setHours(-1)
    nextMonthDateObj.setMonth(currentMonthInView.getMonth()+1)
    let startYear = String(parseCalYear(lastMonthDateObj))
    let endYear = String(parseCalYear(nextMonthDateObj))
    let startMonth = String(parseCalMonth(lastMonthDateObj))
    let endMonth = String(parseCalMonth(nextMonthDateObj))
    let startDay = String(parseCalDay(lastMonthDateObj))
    let endDay = "1"
    let tz = tzLookup[timezone] //deprecations may exist
    let tzs = timezonesConvertedForView
    tzs.push(tz)
    addTimezonesToConverted(tzs)
    // convert currenMonthInView using the following to get first & last
    // utc moments in preferred timezone
    let start = earliestTimestampInZonedMonth(year, month, tz)
    let end = latestTimestampInZonedMonth(year, month, daysInCurrentMonth, tz)
    let yearKey = String(year)
    let monthKey = String(month)
    let zonedMonthObj = tzTransitStore

    // eclipses and retrogrades are more infrequent and therefore
    // all timezone calcs stored in local data file
    ingressForTimezone(start, end, yearKey, monthKey, startDay, startYear, endYear, startMonth, endMonth)
    vocForTimezone(start, end, yearKey, monthKey, startDay,startYear, endYear, startMonth, endMonth)

    if (!zonedMonthObj[yearKey]) {zonedMonthObj[yearKey] = {}}
    if (!zonedMonthObj[yearKey][tz]) {zonedMonthObj[yearKey][tz] = {}}
    if (!zonedMonthObj[yearKey][tz][monthKey]) {zonedMonthObj[yearKey][tz][monthKey] = {}}
    if (!zonedMonthObj[yearKey][tz][monthKey]["days"]) {zonedMonthObj[yearKey][tz][monthKey]["days"] = {}}

    Object.keys(monthObj[year][month]["days"]).forEach((key:string) => {
      if (!zonedMonthObj[yearKey][tz][monthKey]["days"][key]) {zonedMonthObj[yearKey][tz][monthKey]["days"][key] = []}
      monthObj[year][month]["days"][key].forEach((transit:any) => {
        let zonedTransit = convertTransitTime(transit, tz, start, end)

        if (zonedTransit.day) {
          let convertedDay = zonedTransit.day

          let convertedTransit = zonedTransit.transit
          if (zonedMonthObj[yearKey][tz][monthKey]["days"][convertedDay]) {
            zonedMonthObj[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          } else  {
            zonedMonthObj[yearKey][tz][monthKey]["days"][convertedDay] = [] //converted day may be future key
            zonedMonthObj[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          }
        }
      })

    })

    monthObj[startYear][startMonth]["days"][startDay].forEach((transit:any) => {
      let zonedTransit = convertTransitTime(transit, tz, start, end)

      if (zonedTransit.day) {
        let convertedDay = zonedTransit.day

        let convertedTransit = zonedTransit.transit
        if (zonedMonthObj[yearKey][tz][monthKey]["days"][convertedDay] != null) {
          zonedMonthObj[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
        } else {
          zonedMonthObj[yearKey][tz][monthKey]["days"][convertedDay] = []
          zonedMonthObj[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
        }
      }
    })

     monthObj[endYear][endMonth]["days"][endDay].forEach((transit:any) => {
       let zonedTransit = convertTransitTime(transit, tz, start, end)

       if (zonedTransit.day) {
         let convertedDay = zonedTransit.day

         let convertedTransit = zonedTransit.transit
         if (zonedMonthObj[yearKey][tz][monthKey]["days"][convertedDay] != null) {
            zonedMonthObj[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          } else {
            zonedMonthObj[yearKey][tz][monthKey]["days"][convertedDay] = []
            zonedMonthObj[yearKey][tz][monthKey]["days"][convertedDay].push(convertedTransit)
          }
        }
     })

    setTzTransitStore(zonedMonthObj)
  }

  const deepCopyTransit = (transit:any) => {
    return {
      "planet": transit["planet"],
      "details": {
        "sign": transit["details"]["sign"],
        "timestamp": "",
        "planet_degree": transit["details"]["planet_degree"],
        "aspect": {
          "id": transit["details"]["aspect"]["id"],
          "asp": transit["details"]["aspect"]["asp"],
          "orb": transit["details"]["aspect"]["orb"],
        },
        "object_sign": transit["details"]["object_sign"],
        "object_degree": transit["details"]["object_degree"]
      }
    }
  }

  const deepCopyIngressVoc = (transit:any) => {
    return {
      "planet": transit["planet"],
      "details": {
        "timestamp": "",
        "event": transit["details"]["event"],
        "ingressSign": transit["details"]["ingressSign"],
      }
    }
  }

  const convertTransitTime = (transit:any, tz:string, start:number, end:number) => {
    let ts = transit["ms_timestamp"]

    let converted:any = {}
    if ((start <= ts) && (ts <= end)) {
      let adjustedTransit = (transit["details"]["aspect"]) ? deepCopyTransit(transit) : deepCopyIngressVoc(transit)
      let zonedDateTime = getZonedDateTime(ts, tz)
      let zonedPieces = zonedDateTime.split(" ")
      let day = zonedPieces[0].split("-")[2]
      let intDay = parseInt(day)
      let convertedTs = zonedPieces[1].split(":")
      let hour = parseInt(convertedTs[0])
      let minute = parseInt(convertedTs[1])
      let second = parseInt(convertedTs[2])
      // round here in case of day bump to place correctly in obj
      minute = (second > 30) ?  minute + 1 : minute

      if (minute > 59) {
        hour += 1
        minute = 0
      }
      if ( hour === 24 ) {
        hour = 0
        intDay += 1
      }
      let strHour = (hour < 10) ?  `0${hour}` : `${hour}`
      let strMinute = (minute < 10) ? `0${minute}` : `${minute}`
      adjustedTransit["details"]["timestamp"] = `${strHour}:${strMinute}:00`

      return {transit: adjustedTransit, day: `${intDay}`}
    }
    return {transit: null, day: null}
  }

  const handleDateSelection = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, day:any, dayHidden:boolean) => {
    if (dayHidden) {
      handleMonthChange(day)
    }
    handleDateChange(day);
  }

  const handleMonthChange = (event:any) => {
    setCurrentMonthInView(event)
  }

  const monthForDate = () => {
    return selectedDate.getMonth() + 1;
  }

  const dayForDate = () => {
    return selectedDate.getDate();
  }

  const yearForDate = () => {
    return selectedDate.getFullYear();
  }

  const dayOfWeekString = () => {
    const d = selectedDate.toLocaleString('en-us', {  weekday: 'long' });
    return d
  }

  const monthForDateString = () => {
    const m = selectedDate.toLocaleString('default', { month: 'short' });
    return m;
  }


  const parseCalDay = (day:any) => {
    return day.getDate();
  }

  const parseCalMonth = (day:any) => {
    return day.getMonth() + 1;
  }

  const parseCalYear = (day:any) => {
    return day.getFullYear();
  }

  const parseMoonPhase = () => {
    let phase = moonsObj[yearForDate()][tzLookup[timezone]][monthForDate()]["days"][dayForDate()];

    if (phase === 0 || phase === 4) {
      return checkForEclipse(selectedDate, phase)
    }
    return phase
  }

  const isNightTheme = (themeColor:number) => {
    return [4,5,6,7].includes(Math.floor(themeColor))
  }

  const checkForEclipse = (day:any, moonPhase:number) => {
    let eclipse = (moonPhase === 0) ? "solar" : "lunar"
    let month = parseCalMonth(day)
    let tz = tzLookup[timezone]

    if (eclipses[parseCalYear(day)][eclipse][tz]["months"].hasOwnProperty(month)) {
      let eclipseDays = eclipses[parseCalYear(day)][eclipse][tz]["months"][month].map((a:any) => a.day);

      if (eclipseDays.includes(parseCalDay(day))) {
        return (eclipse === "solar") ? 9 : 8
      }
    } else {
      return moonPhase
    }
    return moonPhase
  }

  const moonPhaseSignifier = (day:any) => {
    if (!moonsObj[parseCalYear(day)]) return ""
    let moonPhase = moonsObj[parseCalYear(day)][tzLookup[timezone]][parseCalMonth(day)]["days"][parseCalDay(day)]

    //if phase && customBackground > 2 return moon phase white version
    if (moonPhase === 0 || moonPhase === 4) {
      const eclipseType = checkForEclipse(day, moonPhase)
      if (eclipseType === 8 && isNightTheme(customBackground)) { return classes.lunarEclipseWhite; }
      if (eclipseType === 8) { return classes.lunarEclipse; }
      if (eclipseType === 9 && isNightTheme(customBackground)) { return classes.solarEclipseWhite; }
      if (eclipseType === 9) { return classes.solarEclipse; }
    }
    if (moonPhase === 0 && isNightTheme(customBackground)) { return classes.newMoonWhite; }
    if (moonPhase === 0) { return classes.newMoon; } // solar eclipse : dark
    if (moonPhase === 2 && isNightTheme(customBackground)) { return classes.firstQuarterWhite; }
    if (moonPhase === 2) { return classes.firstQuarter; }
    if (moonPhase === 4 && isNightTheme(customBackground)) { return classes.fullMoonWhite; }
    if (moonPhase === 4) { return classes.fullMoon; } // lunar eclipse : light
    if (moonPhase === 6 && isNightTheme(customBackground)) { return classes.lastQuarterWhite; }
    if (moonPhase === 6) { return classes.lastQuarter; }
    return ""
  }

  const handleCalChange = (event:any) => {
    // console.log(event)
  }

  const getSubscriptionMaxDate = () => {
    // if (subscriptionPeriod === "monthly") {
    //   return subscriptionEndDate
    // } else {
    //   return (new Date("2021/12/31"))
    // }
    return subscriptionEndDate
  }

  const getSubscriptionMinDate = () => {
    // if (subscriptionPeriod === "monthly") {
    //   return subscriptionStartDate
    // } else {
    //   return (new Date("2020/01/01"))
    // }
    return (new Date("2020/01/01"))
  }

  const handleSymbolIndexToggle = (panelView:string) => {
    if (panelView === "CALENDAR") {
      setSymbolIndexMode(true)
    } else {
      setSymbolIndexMode(false)
    }
  }

  const noteIndicator = (day:any, hidden:boolean) => {
    const d = parseCalDay(day)
    if (!hidden && noteCollection.includes(d)) {
      return (<span className={classes.noteIndicatorDot}></span>)
    } else {
      return (<span></span>)
    }
  }

  const closeEphemeris = () => {
    setEphemerisMode(false)
  }

  const closeSymbolIndex = () => {
    setSymbolIndexMode(false)
  }

  const handleBackGroundColorChange = (val:number) => {
    setCustomBackground(val)
  }

  const fontColor = () => {
    return (isNightTheme(customBackground)) ? classes.lightText : ""
  }

  const closeHomePage = () => {
    setHomepageMode(false)
  }

  const showHomePage = () => {
    setHomepageMode(true)
  }

  const getDayTransits = (y:number, m:number, d:number, hidden:boolean) => {
    let tz = tzLookup[timezone]
    if (hidden) {
      return []
    } else if (tz === "ETC/UTC") {
      return monthObj[y][m]["days"][d]
    } else if (tzTransitStore[y] && tzTransitStore[y][tz] && tzTransitStore[y][tz][m]) {
      return tzTransitStore[y][tz][m]["days"][d]
    } else {
      return []
    }
  }

  const getDayVoc = () => {
    let tz = tzLookup[timezone]
    if (tzVocStore[yearForDate()] && tzVocStore[yearForDate()][tz] && tzVocStore[yearForDate()][tz][monthForDate()]
      && tzVocStore[yearForDate()][tz][monthForDate()]["days"][dayForDate()]) {
      return (tzVocStore[yearForDate()][tz][monthForDate()]["days"][dayForDate()])
    } else {
      return []
    }
  }

  const getDayIngress = () => {
    let tz = tzLookup[timezone]
    if (tzIngressStore[yearForDate()] && tzIngressStore[yearForDate()][tz] && tzIngressStore[yearForDate()][tz][monthForDate()]
      && tzIngressStore[yearForDate()][tz][monthForDate()]["days"][dayForDate()]) {
      return (tzIngressStore[yearForDate()][tz][monthForDate()]["days"][dayForDate()])
    } else {
      return []
    }
  }

  const parseRxRd = () => {
    let tz = tzLookup[timezone]
    let year = yearForDate();
    let month = monthForDate();
    let day = dayForDate();
    let directionals = []
    if (rxRd.hasOwnProperty(year) && rxRd[year][tz].hasOwnProperty(month) && rxRd[year][tz][month]["days"].hasOwnProperty(day)) {
      directionals = rxRd[year][tz][month]["days"][day]
    }
    return directionals
  }

  const renderInspiredBy = () => {
    let inspired = <span></span>
    if (Math.floor(customBackground) === 1) {
      inspired = <span>Brought to you by <a href="https://www.lovelanyadoo.com/ghost-of-a-podcast" target="_blank" rel="noreferrer"><i>Ghost of a Podcast</i></a>.</span>
    }
    return (<div className={classes.inspiration}>{inspired}</div>)
  }

  const renderRightPanelContents = () => {
    if (symbolIndexMode) {
      return (<SymbolIndex closeSymbolIndex={() => closeSymbolIndex()}/>)
    } else if (ephemerisMode) {
      return (<Ephemeris month={currentMonthInView} closeEphemeris={() => closeEphemeris()}/>)
    } else if (faqMode) {
      return (<Faq inCal={true} close={() => setFaqMode(false)}/>)
    } else if (userGuideMode) {
      return (<UserGuide close={() => setUserGuideMode(false)}/>)
    } else {
      return (
        <span>
          <TimeZoneToggle timezone={timezone} toggleTimeZone={handleTimeZoneChange}/>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Calendar
                    date={selectedDate}
                    onChange={ (e) => handleCalChange(e) }
                    onMonthChange={ (e) => handleMonthChange(e) }
                    allowKeyboardControl={false}
                    minDate={ getSubscriptionMinDate() }
                    maxDate={ getSubscriptionMaxDate() }
                    renderDay={(day, selectedDate, DayProps, dayComponent) => (
                      <div key={keyCreator()}
                           className={ `${classes.dayContainer} ${fontColor()} ${moonPhaseSignifier(day)}` }
                           onClick={e => handleDateSelection(e, day, dayComponent.props.hidden)}>
                        {dayComponent}
                        {noteIndicator(day, dayComponent.props.hidden)}
                        <DayTransitList day={dayComponent.props.children}
                                        transits={getDayTransits(parseCalYear(day), parseCalMonth(day), parseCalDay(day), dayComponent.props.hidden)}
                                        inCurrentMonth={!(dayComponent.props.hidden)}
                                        small={true}
                                        customColor={customBackground}
                                        key={keyCreator()}/>
                      </div>
                    )}
            />
          </MuiPickersUtilsProvider>
          {renderInspiredBy()}
        </span>
      )
    }
  }

  if (homepageMode) {
    return (<HomePage user={currentUserId} hasActiveSubscription={hasActiveSubscription} closeHomePage={closeHomePage}/>)
  } else if (isSignedIn && hasActiveSubscription) {
    return (
      <Container>
          <LogoContainer onClick={() => setHomepageMode(true)}>
            <SvgLogo className="App-logo"/>
          </LogoContainer>
          <RightPanel>
            {renderRightPanelContents()}
          </RightPanel>
          <LeftPanel>
            <DetailedDay month={monthForDate()}
                         day={dayForDate()}
                         dayOfWeek={dayOfWeekString()}
                         monthAsString={monthForDateString()}
                         year={yearForDate()}
                         moonPhase={parseMoonPhase()}
                         transits={getDayTransits(yearForDate(), monthForDate(), dayForDate(), false)}
                         voc={getDayVoc()}
                         ingress={getDayIngress()}
                         rxRd={parseRxRd()}
                         customColor={customBackground}
                         reloadNoteIndicators={() => getMonthNoteDates()}
                         />
            <BottomNav toggleSymbolIndex={(val:string) => handleSymbolIndexToggle(val)}
                       calSymbolVal={symbolIndexMode}
                       backgroundChange={(val:number) => handleBackGroundColorChange(val) }
                       key={keyCreator()}
                       openFaq={() => setFaqMode(true)}
                       openUserGuide={() => setUserGuideMode(true)}/>
          </LeftPanel>
      </Container>
    )
  } else if (isSignedIn) { //keep this, but make sure homepage accessible
    if (spinnerVisible) {
      return (<Spinner />)
    } else {
      return (<SubscriptionSelectScreen user={currentUserId} closer={() => showHomePage()}/>)
    }
  } else {
    return (<HomePage closeHomePage={closeHomePage}/>)
  }
}

export default App;
