import * as React from "react";

function Libra(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-libra"
      role="img"
      {...props}
    >
      <title id="component-svg-libra">Libra</title>
      <path
        d="M18 10.09C18 6.744 15.306 4 12 4s-6 2.743-6 6.09c0 1.032.263 2.03.743 2.91H2a1 1 0 000 2h8a1 1 0 000-2h-.782A4.106 4.106 0 018 10.09C8 7.84 9.806 6 12 6s4 1.84 4 4.09c0 1.132-.419 2.143-1.182 2.91H14a1 1 0 000 2h7.875a1 1 0 000-2h-4.593A6.128 6.128 0 0018 10.09zM22 19a1 1 0 000-2H2a1 1 0 000 2h20z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Libra;
