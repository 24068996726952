import React from 'react';
import { useEffect, useState } from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import SvgLogo from '../Logo'
import HDAirVideo from '../../assets/videos/hd-air-clouds.mp4'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { keyCreator } from "../../utils";
import SignInScreen from '../Firebase';
import Faq from './Faq';
import About from './About';
import Demo from './Demo';
import TermsOfService from '../TermsOfService';
import PrivacyPolicy from '../PrivacyPolicy';
import SubscriptionSelectScreen from '../SubscriptionSelect/SubscriptionSelectScreen';
import Footer from './Footer';
import Contact from './Contact';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    homepage: {
      height: "100%",
      overflowX: "hidden",
      ["@media (max-width:770px)"]: {
        height: "100vh",
        maxHeight: "unset"
      }
    },
    homPageVideo: {
      position: "fixed",
      right: "0",
      bottom: "0",
      minWidth: "100%",
      minHeight: "100%",
    },
    logoContainer: {
      position: "relative",
      marginTop: "10%",
      ["@media (max-width:1024px)"]: {
        marginTop: "15%"
      },
      ["@media (max-width:770px)"]: {
        marginTop: "25%",
      }
    },
    homepageAppLogo: {
      position: "relative",
      display: "block",
      width: '400px',
      height: '400px',
      margin: "0 auto",
      ["@media (max-width:770px)"]: {
        width: '260px',
        height: '260px',
      }
    },
    signInLogoContainer: {
      cursor: "pointer",
      ["@media (min-width:1025px)"]: {
        position: "relative",
        display: "inline-block",
        top: "20px",
        marginTop: 0,
        left: "20px",
        textAlign: "left",
        transition: "all .5s ease-in-out"
      }
    },
    signInAppLogo: {
      ["@media (min-width:1025px)"]: {
        width: "200px",
        height: "200px",
        marginLeft: 0,
        transition: "all .5s ease-in-out"
      },
      ["@media (max-width:1024px)"]: {
        display: "none"
      }
    },
    signInMode: {
      position: "relative",
      top: 0,
      opacity: "1",
      zIndex: 10,
      transition: "opacity .5s ease-in-out",
      ["@media (max-width:1024px)"]: {
        top: "5%"
      }
    },
    hiddenSignIn: {
      position: "relative",
      transition: "opacity .5s ease-in-out",
      opacity: "0",
      zIndex: -1000
    },
    account: {
      paddingTop: "10px"
    },
    tickerScroller: {
      position: "relative",
      animation: "$ticker-tape-scrolling 10s linear infinite running",
      "&:hover": {
        animationPlayState: "paused"
      },
      ["@media (max-width:1024px)"]: {
        animation: "$ticker-tape-scrolling 5s linear infinite running",
      }
    },
    tickerTape: {
      verticalAlign: "middle",
      letterSpacing: "0.1em",
      fontFamily: "VT323",
    },
    tickerPhrase: {
      borderRight: "1px solid black",
      padding: "0px 10px 0px 10px",
      "& a": {
        color: "#333",
        cursor: "pointer",
        fontSize: "2em"
      },
      "& a:hover": {
        color: "#000",
        fontWeight: "bold"
      }
    },
    "@keyframes ticker-tape-scrolling": {
      "0%": {
        left: 0
      },
      "100%": {
        left: "-50%"
      }
    },
  })
);

const HomePageNav = styled('div')({
  width: "58%",
  float: "right",
  marginTop: "40px",
  paddingRight: "2%",
  textAlign: "right",
  ["@media (max-width:1440px)"]: {
  },
  ["@media (max-width:1024px)"]: {
    width: "100%",
    textAlign: "center",
    paddingRight: 0,
  },
  ["@media (max-width:400px)"]: {
    marginTop: "30px",
  }
});

const NavItem = styled('button')({
  background: "none",
  border: "none",
  padding: 0,
  fontWeight: "bold",
  fontSize: "1.4em",
  fontFamily: "Horizon",
  marginLeft: "6%",
  cursor: "pointer",
  textDecoration: "none",
  color: "#000",
  "&:hover": {
    color: "#fff"
  },
  ["@media (max-width:1024px)"]: {
    marginLeft: "5%",
    fontSize: "2em",
    "&:first-child": {
      margin: 0
    }
  },
  ["@media (max-width:770px)"]: {
    fontSize: "1.6em"
  },
  ["@media (max-width:600px)"]: {
    fontSize: "1em",
  },
  ["@media (max-width:300px)"]: {
    fontSize: "0.6em"
  }
});

const TickerTape = styled('div')({
  position: "absolute",
  bottom: 0,
  width: "100vw",
  height: "35px",
  paddingTop: "10px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  ["@media (max-width:770px)"]: {
    bottom: "25px"
  }
})

export default function HomePage(props:any) {
  const classes = useStyles();
  const user = props.user;
  const subscribed = props.hasActiveSubscription;
  const [termsOfServiceMode, setTermsOfServiceMode] = useState(false)
  const [privacyPolicyMode, setPrivacyPolicyMode] = useState(false)
  const [subscriptionMode, setSubscriptionMode] = useState(false)
  const [featureMode, setFeatureMode] = useState("")
  const backToCal = props.closeHomePage
  let signInClass = ((!user && subscriptionMode) || featureMode === "signIn") ? classes.signInMode : classes.hiddenSignIn
  let signInAppLogo = ((!user && subscriptionMode) || featureMode.length > 0) ? classes.signInAppLogo : ""
  let signInLogoContainer = ((!user && subscriptionMode) || featureMode.length > 0) ? classes.signInLogoContainer : ""

  useEffect(() => {
    if (window.location.href.includes("#")) {
      let parts = window.location.href.split("#")
      let goTo = parts[parts.length-1]
      if (goTo === "terms-of-service") {
        setTermsOfServiceMode(true)
      } else if (goTo === "privacy-policy") {
        setPrivacyPolicyMode(true)
      } else if (goTo === "contact") {
        setFeatureMode("contact")
      }
    } else {
      return
    }
  },[])

  const signIn = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    firebase.auth().onAuthStateChanged((user:any) => {
      if (user !== null) {
        backToCal();
      } else {
        setFeatureMode("signIn")
      }
    })
  }

  const openFeature = (feature:string) => {
    setFeatureMode(feature)
  }

  const closeFeature = () => {
    setFeatureMode("")
  }

  const closeContactSubmission = () => {
    closeFeature()
    // some thank you animation
  }

  const renderFeaturePage = () => {
    if (featureMode==="faq") {
      return (<Faq close={closeFeature} />)
    } else if (featureMode === "about") {
      return (<About close={closeFeature}/>)
    } else if (featureMode === "demo") {
      return (<Demo />)
    } else if (featureMode === "contact") {
      return (<Contact closeContactFormOnSubmit={closeContactSubmission}/>)
    } else if (featureMode === "signIn") {
      return (
        <div className={`${signInClass}`}>
          <SignInScreen />
        </div>
      )
    } else {
      return (<span></span>)
    }
  }

  const closeInfoPageRequest = () => {
    setTermsOfServiceMode(false)
    setPrivacyPolicyMode(false)
    setSubscriptionMode(false)
    window.location.href = window.location.href.split("#")[0]
  }

  const subscribe = () => {
    closeFeature()
    if (!user) {
      setFeatureMode("signIn");
    } else if (subscribed) {
      backToCal();
    } else {
      setSubscriptionMode(true);
    }
  }

  const toggleHomePage = () => {
    if (featureMode.length > 0) {
      closeFeature()
    }
  }

  const renderHomePage = () => {
    return (
      <Box position="relative" height="100vw" maxHeight="100vh" width="100%" className={classes.homepage}>
        <video autoPlay={true} muted playsInline={true} disablePictureInPicture={true} className={classes.homPageVideo} loop={true} style={{ zIndex: -1 }}>
          <source src={HDAirVideo} type="video/mp4"/>
        </video>
        <HomePageNav >
          <NavItem onClick={() => openFeature("demo")}>TUTORIAL</NavItem>
          <NavItem onClick={() => openFeature("faq")}>FAQ</NavItem>
          <NavItem onClick={(e) => signIn(e)}>SIGN IN</NavItem>
        </HomePageNav>
        <div onClick={() => toggleHomePage()} className={`${classes.logoContainer} ${signInLogoContainer}`}>
          <SvgLogo className={`App-logo ${classes.homepageAppLogo} ${signInAppLogo}`}/>
        </div>

        {renderFeaturePage()}
        <TickerTape>
          <div className={classes.tickerScroller}>
            <span className={classes.tickerTape}>
              {[1,2,3,4,5,6].map((x:any) =>
                <span key={keyCreator()}>
                  <span className={classes.tickerPhrase}><a onClick={subscribe}>SUBSCRIBE TO ASTROLOGY FOR DAYS</a></span>
                  <span className={classes.tickerPhrase}><a onClick={subscribe}>ASTROLOGER TESTED, ASTROLOGER APPROVED</a></span>
                </span>
              )}
            </span>
          </div>
        </TickerTape>
        <Footer openContact={() => setFeatureMode("contact")}
                openPrivacy={() => setPrivacyPolicyMode(true)}
                openTermsOfService={() => setTermsOfServiceMode(true)}
                openFaq={() => setFeatureMode("faq")}
                openAbout={() => setFeatureMode("about")}
                subscribe={() => subscribe()}
                />
      </Box>
    )
  }

  if (termsOfServiceMode) {
    return (<TermsOfService closeSelf={() => closeInfoPageRequest()}/>)
  } else if (privacyPolicyMode) {
    return (<PrivacyPolicy closeSelf={() => closeInfoPageRequest()}/>)
  } else if (subscriptionMode) {
    if (user) {
      return (<SubscriptionSelectScreen currentUserId={user} closer={() => closeInfoPageRequest()}/>)
    } else {
      return (renderHomePage())
    }
  } else {
    return (renderHomePage())
  }
}

