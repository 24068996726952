import * as React from "react";

function SouthNode(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="1em"
      height="1em"
      aria-labelledby="component-svg-south-node"
      role="img"
      {...props}
    >
      <title id="component-svg-south-node">South Node</title>
      <path d="M380.594 101.844c18.406-8.422 31.188-26.969 31.188-48.516C411.781 23.875 387.906 0 358.438 0 329 0 305.109 23.875 305.109 53.328c0 21.656 12.922 40.281 31.453 48.641 23.469 10.172 44.562 24.531 62.75 42.719 18.625 18.625 33.219 40.297 43.438 64.422 10.562 24.984 15.906 51.516 15.906 78.891 0 27.359-5.344 53.906-15.906 78.875-10.219 24.141-24.812 45.812-43.438 64.422-18.625 18.625-40.312 33.25-64.438 43.453-24.969 10.562-51.5 15.906-78.875 15.906s-53.906-5.344-78.875-15.906c-24.125-10.203-45.812-24.828-64.438-43.453-18.625-18.609-33.219-40.281-43.438-64.422-10.56-24.97-15.904-51.517-15.904-78.876 0-27.375 5.344-53.907 15.906-78.891 10.219-24.125 24.812-45.796 43.438-64.422 18.188-18.188 39.281-32.578 62.734-42.734 18.516-8.375 31.422-26.984 31.422-48.625C206.844 23.875 182.953 0 153.5 0s-53.344 23.875-53.344 53.328c0 21.562 12.812 40.125 31.219 48.531C71.453 142.062 32 210.406 32 288c0 123.703 100.281 224 224 224s224-100.297 224-224c0-77.594-39.469-145.969-99.406-186.156zm-54.156-48.516c0-17.641 14.359-32 32-32 17.656 0 32 14.359 32 32s-14.344 32-32 32c-17.641 0-32-14.359-32-32zm-204.938 0c0-17.641 14.359-32 32-32s32 14.359 32 32-14.359 32-32 32-32-14.359-32-32z" />
    </svg>
  );
}

export default SouthNode;
