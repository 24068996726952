import * as React from "react";

function Sextile(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      aria-labelledby="component-svg-sextile sextile-definition"
      role="img"
      {...props}
    >
      <title id="component-svg-sextile">Sextile</title>
      <desc id="sextile-definition">A 60 degree angle between objects.</desc>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path fill="none" opacity={0.3} d="M0 0h24v24H0z" />
          <path d="M19 11h-4.69l4.54-4.85a1 1 0 10-1.46-1.37L12 10.54 6.61 4.78a1 1 0 10-1.46 1.37L9.69 11H5a1 1 0 000 2h4.69l-4.54 4.85a1 1 0 000 1.42 1 1 0 001.42-.05L12 13.46l5.39 5.76a1 1 0 101.46-1.37L14.31 13H19a1 1 0 000-2z" />
        </g>
      </g>
    </svg>
  );
}

export default Sextile;
