import * as React from "react";

function Conjunction(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      aria-labelledby="component-svg-conjunction conjunction-definition"
      role="img"
      {...props}
    >
      <title id="component-svg-conjunction">Conjunction</title>
      <desc id="conjunction-definition">A 0 degree angle between objects. Two bodies conjunct one another are at the same degree.</desc>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path fill="none" opacity={0.3} d="M0 0h24v24H0z" />
          <path d="M18.29 4.29L14.18 8.4a7 7 0 101.42 1.42l4.11-4.11a1 1 0 10-1.42-1.42zM10 19a5 5 0 115-5 5 5 0 01-5 5z" />
        </g>
      </g>
    </svg>
  );
}

export default Conjunction;
