import React from 'react';
import { useState } from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Button, Menu, MenuItem, ClickAwayListener } from '@material-ui/core';
import firebase from 'firebase/compat/app';
import "firebase/compat/functions";
import ColorMenu from '../ColorMenu/ColorMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 'auto',
      left: 0,
      bottom: 0,
      width: '34vw',
      ["@media (max-width:1024px)"]: {
        width: '100vw'
      }
    },
    toolbar: {
      justifyContent: "space-around",

    },
    calFunctionLink: {
      cursor: "pointer !important",
      fontFamily: "Circular",
      fontWeight: "bold",
      ["@media (max-width:320px)"]: {
        fontSize: "0.675rem"
      }
    },
    submenuItem: {
      fontFamily: "Circular",
      fontWeight: "bold"
    }
  })
);

export default function BottomNav(props:any) {
  const classes = useStyles();
  const calSymbolVals = ["GLYPHS", "CALENDAR"]
  const currentValIndex = props.calSymbolVal ? 1 : 0
  const bgChange = props.backgroundChange
  const [calSymbolVal, toggleCalSymbolVal] = useState(calSymbolVals[currentValIndex])
  const [colorMenuVisible, showColorMenu] = useState(false)
  const [accountMenuOpen, toggleAccountMenu] = useState(false)

  const signOut = () => {
    firebase.auth().signOut();
  }

  const toggleSymbolIndex = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const i = calSymbolVals.indexOf(event.currentTarget.value) - 1
    const newVal = calSymbolVals[Math.abs(i)]
    props.toggleSymbolIndex(newVal)
    toggleCalSymbolVal(newVal)
  }

  const toggleColorMenu = () => {
    let menuVisible = colorMenuVisible
    showColorMenu(!menuVisible)
  }

  const handleAccountClick = () => {
    let viewMenu = accountMenuOpen
    toggleAccountMenu(!viewMenu)
  }

  const openFaq = () => {
    props.openFaq()
  }

  const handleSubMenuClose = () => {
    toggleAccountMenu(false)
  }

  const openUserGuide = () => {
    props.openUserGuide();
  }

  async function openStripePortal() {
    handleSubMenuClose();
    const functionRef = firebase
      .functions()
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    let windowReference = window.open();
    const { data } = await functionRef({ returnUrl: window.location.origin });
    if (windowReference) {
      windowReference.location = data.url
    } else {
      window.open(data.url, '_blank');
    }
  }

  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <ColorMenu visibility={colorMenuVisible} backgroundChange={bgChange}/>
      <Toolbar className={classes.toolbar}>
        <ClickAwayListener onClickAway={handleSubMenuClose}>
          <div>
            <Button aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="inherit"
                    id="account-button"
                    onClick={handleAccountClick}
                    className={classes.calFunctionLink}>
                ACCOUNT
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={document.getElementById("account-button")}
              keepMounted
              open={accountMenuOpen}
              onClose={handleSubMenuClose}
            >
              <MenuItem className={classes.submenuItem} onClick={openFaq}>FAQ</MenuItem>
              <MenuItem className={classes.submenuItem} onClick={openUserGuide}>User Guide</MenuItem>
              <MenuItem className={classes.submenuItem} onClick={openStripePortal}>My Subscription</MenuItem>
              <MenuItem className={classes.submenuItem} onClick={() => signOut()}>Logout</MenuItem>
            </Menu>
          </div>
        </ClickAwayListener>
        <Button onClick={() => toggleColorMenu()} color="inherit" className={classes.calFunctionLink}>
          CUSTOMIZE
        </Button>
        <Button onClick={(e) => toggleSymbolIndex(e)}
                value={calSymbolVal}
                color="inherit"
                className={classes.calFunctionLink}>
          {calSymbolVal}
        </Button>
      </Toolbar>
    </AppBar>
  )
}

