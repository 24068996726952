import * as React from "react";

function Scorpio(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-scorpio"
      role="img"
      {...props}
    >
      <title id="component-svg-scorpio">Scorpio</title>
      <path
        d="M17 18.429c0 .868.684 1.571 1.5 1.571s1.5-.703 1.5-1.571v-3.767l-.214.18a1 1 0 11-1.221-1.584l1.824-1.407a1 1 0 011.61.792v5.786C22 20.389 20.435 22 18.5 22c-1.933 0-3.5-1.611-3.5-3.571L15 5.7c0-.964-.68-1.7-1.5-1.7-.819 0-1.5.736-1.5 1.7v14.1a1 1 0 01-2 0V5.7C10 4.736 9.32 4 8.5 4c-.814 0-1.492.728-1.5 1.684V19.8a1 1 0 01-2 0V5.7a1.725 1.725 0 00-1.529-1.708 1.001 1.001 0 01.258-1.984 3.773 3.773 0 012.242 1.126A3.396 3.396 0 018.501 2c.986 0 1.868.423 2.5 1.103A3.391 3.391 0 0113.5 2C15.458 2 17 3.664 17 5.7L17 18.43z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Scorpio;
