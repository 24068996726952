import React from 'react';
import { useState } from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import ReactQuill, { Mixin, Quill } from "react-quill";
import { keyCreator } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editButton: {
      marginLeft: "5%",
      marginBottom: "5px"
    },
    headerControls: {
      marginBottom: "10px"
    },
    yellowGreenBlue: {
      background: "linear-gradient(180deg, #DDF9FB 0%, #EAFFF2 100%)",
      "& :hover": {
        background: "linear-gradient(180deg, #DDF9FB 0%, #EAFFF2 100%)",
      }
    },
    pinkWhite: {
      background: "linear-gradient(to right, rgb(251,203,219) 0%, rgb(255, 252, 254) 100%);",
      "& :hover": {
        background: "linear-gradient(to right, rgb(251,203,219) 0%, rgb(255, 252, 254) 100%);",
      }
    },
    blackGray: {
      background: "linear-gradient(19deg, rgb(0, 0, 0) 0%, rgb(115, 115, 115) 100%)",
      color: "white",
      "& :hover": {
        background: "linear-gradient(19deg, rgb(0, 0, 0) 0%, rgb(115, 115, 115) 100%)",
        color: "white"
      }
    },
    blackBlue: {
      background: "linear-gradient(33deg, rgb(4, 0, 30) 0%, rgb(0, 143, 179) 100%)",
      color: "white",
      "& :hover": {
        background: "linear-gradient(33deg, rgb(4, 0, 30) 0%, rgb(0, 143, 179) 100%)",
        color: "white"
      }
    },
    yellowPink: {
      background: "linear-gradient(56deg, rgb(248, 240, 120) 0%, rgb(225, 154, 210) 100%)",
      "& :hover": {
        background: "linear-gradient(56deg, rgb(248, 240, 120) 0%, rgb(225, 154, 210) 100%)",
      }
    },
    brownPink: {
      background: "linear-gradient(180deg, rgb(203, 134, 76) 0%, rgb(253, 195, 199) 100%)",
      "& :hover": {
        background: "linear-gradient(180deg, rgb(203, 134, 76) 0%, rgb(253, 195, 199) 100%)",
      }
    },
    whiteLavender: {
      background: "linear-gradient(180deg, rgb(248, 245, 233) 0%, rgb(245, 233, 248) 100%)",
      "& :hover": {
        background: "linear-gradient(180deg, rgb(248, 245, 233) 0%, rgb(245, 233, 248) 100%)",
      }
    },
    blackBrown: {
      background: "linear-gradient(33deg, rgb(4, 0, 30) 0%, rgb(157, 60, 16) 100%)",
      color: "white",
      "& :hover": {
        background: "linear-gradient(33deg, rgb(4, 0, 30) 0%, rgb(157, 60, 16) 100%)",
        color: "white"
      }
    },
    pinkRed: {
      background: "linear-gradient(56deg, #F1BEC1  0%, #E42929 100%)",
      "& :hover": {
        background: "linear-gradient(56deg, #F1BEC1  0%, #E42929 100%)"
      }
    },
  })
)

const NotesHeader = styled('span')({
  fontWeight: "bold",
  fontSize: "1.5em",
  ["@media (max-width:1200px)"]: {
    fontSize: "1em"
  }
});

export default function Note(props:any) {
  const classes = useStyles();
  const bgColors = [
    classes.yellowGreenBlue,
    classes.pinkWhite,
    classes.yellowPink,
    classes.whiteLavender,
    classes.blackBlue,
    classes.blackGray,
    classes.brownPink,
    classes.blackBrown,
    classes.pinkRed
  ]


  const renderTextNode = (x:any) => {
    let textNode:any
    switch (x.type) {
      case 'paragraph':
        textNode = `<p key=${keyCreator()}>${x.children.map((c:any)=>c.text).join(' ')}</p>`
        break
      case 'code':
        textNode = `<p key=${keyCreator()}>${x.children.map((c:any)=>c.text).join(' ')}</p>`
        break
      default:
        textNode = `<p key=${keyCreator()}>${x.children.map((c:any)=>c.text).join(' ')}</p>`
    }
    return (textNode)
  }

  const renderExistingNote = () => {
    // handle pre-existing slate generated notes as objects
    if (typeof props.text === "object") {
      let convertedHTML = props.text.map((x:any) => renderTextNode(x)).join("")
      return convertedHTML
    } else {
      if (props.text && props.text.length > 0) {
        return props.text
      } else {
        return <span></span>
      }
    }
  }
  const [value, setValue] = useState(renderExistingNote());


  return (
    <div>
      <NotesHeader>Notes</NotesHeader>
      <Button className={`${classes.editButton} ${bgColors[props.customColor]}`}
              onClick={props.clickFunction}>
        EDIT
      </Button>
      <ReactQuill theme="snow"
                  value={value}
                  modules={{
                    toolbar: false,
                    clipboard: {
                      matchVisual: false,
                    }
                  }}
                  readOnly={true}/>
    </div>
  )
}
