import * as React from "react";

function Uranus(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-uranus"
      role="img"
      {...props}
    >
      <title id="component-svg-uranus">Uranus</title>
      <path
        d="M14.95 18a2.95 2.95 0 11-5.9 0 2.95 2.95 0 015.9 0M19 2c-.556 0-1 .446-1 .996V7h-5V3.996A.998.998 0 0012 3c-.556 0-1 .446-1 .996V7H6V2.996A.998.998 0 005 2c-.556 0-1 .446-1 .996v9.008c0 .551.448.996 1 .996.556 0 1-.446 1-.996V9h5v4.004l.001.045a5.05 5.05 0 101.998 0l.001-.045V9h5v3.004c0 .551.448.996 1 .996.556 0 1-.446 1-.996V2.996A.998.998 0 0019 2"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Uranus;
