import * as React from "react";

function Venus(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      aria-labelledby="component-svg-venus"
      role="img"
      {...props}
    >
      <title id="component-svg-venus">Venus</title>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path fill="none" opacity={0.3} d="M0 0h24v24H0z" />
          <path d="M11 15.1v1.75H8.2a1 1 0 100 2H11v2.27a1 1 0 102 0v-2.27h3a1 1 0 000-2h-3V15.1a6 6 0 10-2 0zm1-9.91a4 4 0 11-4 4 4 4 0 014-4z" />
        </g>
      </g>
    </svg>
  );
}

export default Venus;
