import * as React from "react";

function FullMoon(props:any) {
  let height = (props && props.height) ? props.height : 22
  let width = (props && props.width) ? props.width : 22
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width={width}
      height={height}
      style={{
        msTransform: "rotate(360deg)",
        WebkitTransform: "rotate(360deg)",
      }}
      viewBox="0 0 24 24"
      transform="rotate(360)"
      {...props}
    >
      <path
        d="M12 20a8 8 0 118-8 8 8 0 01-8 8m0-18a10 10 0 1010 10A10 10 0 0012 2z"
        fill="#000000"
      />
      <path fill="rgba(0, 0, 0, 0)" d="M0 0h24v24H0z" />
    </svg>
  );
}

export default FullMoon;
