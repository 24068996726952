import React from 'react';
import Aquarius from './Aquarius'
import Aries from './Aries'
import Cancer from './Cancer'
import Capricorn from './Capricorn'
import Chiron from './Chiron'
import Conjunction from './Conjunction'
import Gemini from './Gemini'
import Ingress from './Ingress'
import Jupiter from './Jupiter'
import Leo from './Leo'
import Libra from './Libra'
import Mars from './Mars'
import Mercury from './Mercury'
import Moon from './Moon'
import Neptune from './Neptune'
import NorthNode from './NorthNode'
import Opposition from './Opposition'
import Pisces from './Pisces'
import Pluto from './Pluto'
import PlutoEphemeris from './PlutoEphemeris'
import Sagittarius from './Sagittarius'
import Saturn from './Saturn'
import Scorpio from './Scorpio'
import Sextile from './Sextile'
import SouthNode from './SouthNode'
import Square from './Square'
import Sun from './Sun';
import Taurus from './Taurus';
import Trine from './Trine';
import Uranus from './Uranus';
import Venus from './Venus';
import Virgo from './Virgo';
import FirstQuarter from '../PhasedMoon/FirstQuarter'
import FullMoon from '../PhasedMoon/FullMoon'
import LastQuarter from '../PhasedMoon/LastQuarter'
import NewMoon from '../PhasedMoon/NewMoon'
import WaningCrescent from '../PhasedMoon/WaningCrescent'
import WaningGibbous from '../PhasedMoon/WaningGibbous'
import WaxingCrescent from '../PhasedMoon/WaxingCrescent'
import WaxingGibbous from '../PhasedMoon/WaxingGibbous'
import SolarEclipse from '../PhasedMoon/SolarEclipse'
import LunarEclipse from '../PhasedMoon/LunarEclipse'

export const planets: { [key: string]: any } = {
  "Sun": Sun,
  "Moon": Moon,
  "Mercury": Mercury,
  "Venus": Venus,
  "Mars": Mars,
  "Jupiter": Jupiter,
  "Saturn": Saturn,
  "Chiron": Chiron,
  "Uranus": Uranus,
  "Neptune":  Neptune,
  "Pluto": Pluto,
  "Pluto (Ephemeris)": PlutoEphemeris,
  "North Node": NorthNode,
  "South Node": SouthNode,
};

export const signs: { [key: string]: any } = {
  "Aries": Aries,
  "Taurus": Taurus,
  "Gemini": Gemini,
  "Cancer": Cancer,
  "Leo": Leo,
  "Virgo": Virgo,
  "Libra": Libra,
  "Scorpio": Scorpio,
  "Sagittarius": Sagittarius,
  "Capricorn": Capricorn,
  "Aquarius": Aquarius,
  "Pisces": Pisces,
};

export const movements: { [key: string]: any } = {
  "0": Conjunction,
  "60": Sextile,
  "90": Square,
  "120": Trine,
  "180": Opposition,
  "Ingress": Ingress
};

export const moons: { [key: string]: any } = {
  "Solar Eclipse": SolarEclipse,
  "Lunar Eclipse": LunarEclipse,
  "First Quarter Moon": FirstQuarter,
  "Full Moon": FullMoon,
  "Last Quarter Moon": LastQuarter,
  "New Moon": NewMoon,
  "Waning Gibbous": WaningGibbous,
  "Waning Crescent": WaningCrescent,
  "Waxing Crescent": WaxingCrescent,
  "Waxing Gibbous": WaxingGibbous,
};
