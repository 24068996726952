import * as React from "react";

function Opposition(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      aria-labelledby="component-svg-opposition opposition-definition"
      role="img"
      {...props}
    >
      <title id="component-svg-opposition">Opposition</title>
      <desc id="opposition-definition">A 180 degree angle between objects.</desc>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path fill="none" opacity={0.3} d="M0 0h24v24H0z" />
          <path d="M12.46 4.46a5 5 0 00-.63 6.3l-1.07 1.07a5 5 0 101.41 1.41l1.07-1.07a5 5 0 10-.78-7.71zm-2.34 13.66a3 3 0 01-4.24-4.24 3 3 0 014.24 0 3 3 0 010 4.24zm8-8a3 3 0 11-4.24-4.24 3 3 0 014.24 0 3 3 0 010 4.24z" />
        </g>
      </g>
    </svg>
  );
}

export default Opposition;
