import React from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import JessicaAvatar from "../../assets/images/Jessica.png";
import AndersAvatar from "../../assets/images/Anders.png";
import { SvgIcon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    aboutHeader: {
      fontFamily: "Horizon",
      marginTop: 0
    },
    bio: {
      width: "60%",
      textAlign: "center",
      padding: "20px",
      paddingTop: "30px",
      fontSize: "1.1em",
      ["@media (max-width:1024px)"]: {
        width: "90%",
        fontSize: "1em",
        padding: "5%"
      }
    },
    imageContainer: {
      display: "inline",
      ["@media (max-width:1024px)"]: {
        textAlign: "center"
      }
    },
    image: {
      width: "250px"
    },
    andersImage: {
      ["@media (max-width:1024px)"]: {
        order: 1
      }
    },
    andersBio: {
      ["@media (max-width:1024px)"]: {
        order: 2
      }
    },
    aboutName: {
      fontFamily: "Horizon",
      fontSize: "1rem"
    },
    bioPic: {
      display: "inline"
    },
    closeContainer: {
      textAlign: "left",
      marginLeft: "-30px",
      ["@media (max-width:1024px)"]: {
        marginLeft: 0
      }
    },
    closer: {
      background: "none",
      color: "inherit",
      border: "none",
      padding: 0,
      cursor: "pointer",
      "& svg": {
        fontSize: "3.5em"
      },
    }
  })
)

const AboutContainer = styled('div')({
  width: "50vw",
  height: "60vh",
  margin: "0 auto",
  position: 'relative',
  borderRadius: "12px",
  textAlign: "center",
  padding: "50px",
  paddingTop: "20px",
  overflow: "scroll",
  background: "linear-gradient(to bottom, rgb(241, 190, 193) 0%, rgb(255, 252, 254) 100%)",
  ["@media (max-width:770px)"]: {
    width: "80vw",
    padding: "20px"
  }
});

const Us = styled('div')({
  fontWeight: "bold",
  fontSize: "1.1em",
  width: "70%",
  margin: "0 auto",
  ["@media (max-width:1024px)"]: {
    width: "80%",
    fontSize: "1em",
  },
});


const Jessica = styled('div')({
  textAlign: "left",
  display: "flex",
  ["@media (max-width:1024px)"]: {
    flexDirection: "column"
  }
});

const Anders = styled('div')({
  textAlign: "right",
  display: "flex",
  marginTop: "10px",
  justifyContent: "flex-end",
  ["@media (max-width:1024px)"]: {
    flexDirection: "column"
  }
});

export default function About(props:any) {
  const classes = useStyles();
  const close = props.close

  return (
    <AboutContainer>
      <div className={classes.closeContainer}><button className={classes.closer} onClick={() => close()}><SvgIcon component={CloseIcon} viewBox="0 0 40 40" /></button></div>
      <h1 className={classes.aboutHeader}>ABOUT US</h1>
      <Us>
        <p>We believe passionately in the power of study. We believe that the most effective way to learn is by exploring one's own unique lived experience.</p>
        <p>We built Astrology for Days to support a deepening understanding of astrology. With transit calculations you can rely on and a streamlined space to support your astrology workflow, this web-based tool is astrologer tested, astrologer approved.</p>
      </Us>
      <Jessica>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={JessicaAvatar} alt="Jessica Lanyadoo"/>
          <div className={classes.aboutName}>JESSICA LANYADOO</div>
        </div>
        <p className={classes.bio}>Jessica is a 12th house Capricorn. Since the mid 90’s, she has worked with clients around the globe to help people help themselves. She has lectured extensively on astrology, hosts a top-rated podcast, and is a published author. At every turn her highest intention is to support people in their quest to live in alignment and with integrity.</p>
      </Jessica>
      <Anders>
        <p className={`${classes.bio} ${classes.andersBio}`}> Anders is an 8th house Gemini. Equal parts poet and software engineer, he's always on the lookout for trines, even if he's more accustomed to living that square life. He is committed to justice, equality, visibility, and service, and he uses technology to make the world a better, kinder place.</p>
        <div className={`${classes.imageContainer} ${classes.andersImage}`}>
          <img className={classes.image} src={AndersAvatar} alt="Anders Howerton"/>
          <div className={classes.aboutName}>ANDERS HOWERTON</div>
        </div>
      </Anders>
    </AboutContainer>
  )
}
