import React from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userGuideHeader: {
      textAlign: "center",
      fontFamily: "Horizon",
      ["@media (max-width:770px)"]: {
        fontSize: ".88em"
      }
    },
    guideList: {
      ["@media (max-width:770px)"]: {
        paddingLeft: "20px"
      }
    },
    primaryListItem: {
      paddingLeft: "5px",
      marginBottom: "10px",
      fontSize: "1.25em",
      ["@media (max-width:770px)"]: {
        fontSize: ".88em"
      }
    },
    green: {
      color: "#009a1f"
    },
    blue: {
      color: "#008fb3",
    },
    black: {
      color: "black",
    },
    red: {
      color: "#b40035",
    },
    closer: {
      background: "none",
      color: "inherit",
      border: "none",
      padding: 0,
      cursor: "pointer",
      "& svg": {
        height: "2em",
        width: "2em"
      }
    }
  })
)

const UserGuideContainer = styled('div')({
  position: "relative",
  top: 0,
  width: "80%",
  marginLeft: "10%",
  paddingTop: "5%",
  paddingBottom: "5%",
  ["@media (max-width:770px)"]: {
    width: "90%",
    marginLeft: "5%",
    maxHeight: "85%",
    overflow: "scroll"
  }
});


 const UserGuide = (props:any) => {
  const classes = useStyles();
  const close = props.close;
  return (
    <UserGuideContainer id="user-guide">
      <div>
      <button className={classes.closer} onClick={() => close()}><SvgIcon component={CloseIcon} viewBox="0 0 40 40" /></button>
      <h1 className={classes.userGuideHeader}>USER GUIDE</h1>
      <ul className={classes.guideList}>
        <li className={classes.primaryListItem}> Each day in the calendar is clickable.
          <ul>
            <li>On larger screens: the transits populate on the left-hand side of your screen</li>
            <li>On smaller screens: the transits populate just below the calendar</li>
          </ul>
        </li>
        <li className={classes.primaryListItem}> In the detailed transit list, the transits are color coded:
          <ul>
            <li className={classes.green}>The Moon's transits are always written in green.</li>
            <li className={classes.blue}>Planetary ingresses appear in blue.</li>
            <li className={classes.black}>Planetary transits are always written in black.</li>
            <li className={classes.red}>Rx and Stations are always notated in red.</li>
          </ul>
        </li>
        <li className={classes.primaryListItem}> Toggle to the Notes feature to write and save your notes.
            Once saved, there will be a red dot on the calendar page as a reminder.
        </li>
        <li className={classes.primaryListItem}> Click the name of the month at the top of the calendar to view that month's <strong>Ephemeris</strong>.
            (i.e. Click on "December" to see December's Ephemeris.)
        </li>
        <li className={classes.primaryListItem}> Color customization is available by clicking the "CUSTOMIZE" button at the bottom of the screen.</li>
        <li className={classes.primaryListItem}> Click the "SYMBOL INDEX" at the bottom of the screen to see the glyphs' English translations.</li>
      </ul>
      </div>
    </UserGuideContainer>
  )
}

export default UserGuide;
