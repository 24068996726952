import * as React from "react";

function LunarEclipse(props:any) {
  let height = (props && props.height) ? props.height : 22
  let width = (props && props.width) ? props.width : 22
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      {...props}
    >
      <path d="M50 93.91C25.79 93.91 6.09 74.21 6.09 50S25.79 6.09 50 6.09 93.91 25.79 93.91 50 74.21 93.91 50 93.91zm0-83.82c-22.01 0-39.91 17.9-39.91 39.91S27.99 89.91 50 89.91 89.91 72.01 89.91 50 72.01 10.09 50 10.09z" />
      <path d="M81.1 23.07H18.9c-.55 0-1-.45-1-1s.45-1 1-1h62.2c.55 0 1 .45 1 1s-.45 1-1 1zM90.56 39.29H9.44c-.55 0-1-.45-1-1s.45-1 1-1h81.12c.55 0 1 .45 1 1s-.45 1-1 1zM91.91 55.52H8.09c-.55 0-1-.45-1-1s.45-1 1-1h83.82c.55 0 1 .45 1 1s-.45 1-1 1zM86.5 71.74h-73c-.55 0-1-.45-1-1s.45-1 1-1h73c.55 0 1 .45 1 1s-.44 1-1 1zM70.28 87.97H29.72c-.55 0-1-.45-1-1s.45-1 1-1h40.56c.55 0 1 .45 1 1s-.45 1-1 1z" />
    </svg>
  );
}

export default LunarEclipse;
