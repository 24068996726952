import React from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FirstQuarter from './FirstQuarter';
import FullMoon from './FullMoon';
import LastQuarter from './LastQuarter';
import NewMoon from './NewMoon';
import WaningCrescent from './WaningCrescent';
import WaningGibbous from './WaningGibbous';
import WaxingCrescent from './WaxingCrescent';
import WaxingGibbous from './WaxingGibbous';
import SolarEclipse from './SolarEclipse';
import LunarEclipse from './LunarEclipse';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sizedMoon: {
      height: "50px",
      width: "50px",
      ["@media (max-width:1440px)"]: {
        height: "35px",
        width: "35px"
      }
    },
  })
);

const symbols = [
  NewMoon,
  WaxingCrescent,
  FirstQuarter,
  WaxingGibbous,
  FullMoon,
  WaningGibbous,
  LastQuarter,
  WaningCrescent,
  LunarEclipse,
  SolarEclipse
]

const BaseMoon = styled('div')({
  width: '60px',
  height: '60px',
  ["@media (max-width:1440px)"]: {
    width: "40px",
    height: "40px"
  }
});

const renderGlyph = (x:number, styleClass:string) => {
  const TagName = symbols[x]
  return <TagName className={styleClass} width={60} height={60}/>;
}

export default function PhasedMoon(descriptors:any) {
  const classes = useStyles();
  return (
    <BaseMoon >
      {renderGlyph(descriptors.moonPhase, classes.sizedMoon)}
    </BaseMoon>
  )
}
