import * as React from "react";

function NewMoon(props:any) {
  let height = (props && props.height) ? props.height : 22
  let width = (props && props.width) ? props.width : 22
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width={width}
      height={height}
      style={{
        msTransform: "rotate(360deg)",
        WebkitTransform: "rotate(360deg)",
      }}
      viewBox="0 0 24 24"
      transform="rotate(360)"
      {...props}
    >
      <path d="M12 2A10 10 0 112 12 10 10 0 0112 2z" fill="#000000" />
      <path fill="rgba(0, 0, 0, 0)" d="M0 0h24v24H0z" />
    </svg>
  );
}

export default NewMoon;
