import * as React from "react";

function PlutoEphemeris(props:any) {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        width="1em"
        height="1em"
        aria-labelledby="component-svg-pluto-ephemeris pluto-ephemeris-description"
        role="img"
        {...props}
      >
        <title id="component-svg-pluto-ephemeris">Pluto (Ephemeris)</title>
        <desc id="pluto-ephemeris-description">The Swiss Ephemeris uses a different version of the Pluto glyph than used elsewhere in the app.</desc>
        <path d="M28.812 33.5H12.688v-6.121h5.685a11.94 11.94 0 000-23.879h-7.185a1.5 1.5 0 00-1.5 1.5v30a1.5 1.5 0 001.5 1.5h17.624a1.5 1.5 0 000-3zm-10.439-27a8.94 8.94 0 010 17.879h-5.685V6.5z" />
      </svg>
  );
}

export default PlutoEphemeris;
