import * as React from "react";

function Ingress(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      aria-labelledby="component-svg-ingress ingress-definition"
      role="img"
      {...props}
    >
      <title id="component-svg-ingress">Ingress</title>
      <desc id="ingress-definition">Movement into a sign.</desc>
      <path d="M60.3 12.8l-7.4 7.3 24.7 24.7H2.5v10.4h75.1L52.9 79.9l7.4 7.3L97.5 50z" />
    </svg>
  );
}

export default Ingress;
