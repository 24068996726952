import * as React from "react";

function Taurus(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-taurus"
      role="img"
      {...props}
    >
      <title id="component-svg-taurus">Taurus</title>
      <path
        d="M20 13A8 8 0 118.053 6.04a8.458 8.458 0 01-2.695-2.324 1 1 0 011.59-1.211c1.12 1.47 2.742 2.373 4.566 2.51.16-.01.811-.01.972 0 1.827-.137 3.452-1.044 4.572-2.52a1 1 0 111.593 1.21 8.46 8.46 0 01-2.704 2.335A7.997 7.997 0 0120 13zM6 13a6 6 0 106.482-5.981c-.16.009-.804.009-.964 0A6 6 0 006 13z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Taurus;
