import * as React from "react";

function SolarEclipse(props:any) {
  let height = (props && props.height) ? props.height : 22
  let width = (props && props.width) ? props.width : 22
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      {...props}
    >
      <path d="M4.2 66.5h109.2c-.2-3-.6-6-1.3-8.9H5.5c-.7 2.9-1.1 5.9-1.3 8.9zM108.8 92c1.3-2.9 2.3-5.8 3.1-8.9H5.7c.8 3.1 1.8 6.1 3.1 8.9h100zM107 95.8H10.7c1.7 3.2 3.7 6.2 6 8.9H101c2.2-2.7 4.2-5.7 6-8.9zM97.5 108.5H20.2c3.5 3.5 7.4 6.5 11.8 8.9h53.8c4.2-2.4 8.2-5.4 11.7-8.9zM113.5 70.3H4.1c0 3 .3 6 .8 8.9h107.8c.5-2.8.8-5.8.8-8.9zM40.8 18.1h36.1c-5.6-2-11.7-3.1-18-3.1-6.4.1-12.5 1.2-18.1 3.1zM77.4 121.3H40.2c5.8 2.1 12.1 3.3 18.6 3.3 6.6-.1 12.8-1.2 18.6-3.3zM10.1 44.9c-1.5 2.8-2.7 5.8-3.6 8.9h104.7c-.9-3.1-2.2-6.1-3.6-8.9H10.1zM19.1 32.1c-2.6 2.7-4.9 5.7-6.9 8.9h93.1c-2-3.2-4.3-6.2-6.9-8.9H19.1zM32.3 22c-3.3 1.8-6.3 4-9.1 6.4h71.3c-2.8-2.4-5.9-4.6-9.1-6.4H32.3z" />
    </svg>
  );
}

export default SolarEclipse;
