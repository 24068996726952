import * as React from "react";

function Square(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      aria-labelledby="component-svg-square square-definition"
      role="img"
      {...props}
    >
      <title id="component-svg-square">Square</title>
      <desc id="square-definition">A 90 degree angle between objects.</desc>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path fill="none" opacity={0.3} d="M0 0h24v24H0z" />
          <path d="M18.75 4.5h-13a1 1 0 00-1 1v13a1 1 0 001 1h13a1 1 0 001-1v-13a1 1 0 00-1-1zm-1 13h-11v-11h11z" />
        </g>
      </g>
    </svg>
  );
}

export default Square;
