import * as React from "react";

function Capricorn(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-capricorn"
      role="img"
      {...props}
    >
      <title id="component-svg-capricorn">Capricorn</title>
      <path
        d="M18.216 11.615C20.86 11.615 23 9.462 23 6.808 23 4.153 20.86 2 18.216 2c-2.643 0-4.783 2.153-4.783 4.808 0 .32.03.634.09.937-.817-.877-1.557-2.105-2.313-3.774-.379-.835-1.586-.762-1.861.114l-3.165 10.07L3.753 6.17a.998.998 0 10-1.91.583L5.217 17.83c.287.94 1.613.946 1.907.009l3.404-10.841s.163.27.245.4c1.337 2.102 2.84 3.33 4.753 3.875C18.299 16.858 16.876 20 11.677 20h-1.376a.999.999 0 000 2h1.376c6.468 0 8.704-4.229 6.185-10.39.117.004.235.005.354.005zm0-2a2.798 2.798 0 01-2.787-2.807A2.798 2.798 0 0118.216 4a2.798 2.798 0 012.788 2.808 2.798 2.798 0 01-2.788 2.807z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Capricorn;
