import * as React from "react";

function Gemini(props:any) {
  return (
    <svg
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="component-svg-gemini"
      role="img"
      {...props}
    >
      <title id="component-svg-gemini">Gemini</title>
      <path
        d="M9 18.393V5.803c1.965.348 4.031.343 6 .01v12.566a17.422 17.422 0 00-6 .014zm8 .471V5.437c0-.027-.013-.05-.015-.076a13.97 13.97 0 002.481-1.005 1 1 0 10-.932-1.77c-3.79 1.999-9.306 1.979-13.056-.064a1 1 0 10-.956 1.756c.775.423 1.61.77 2.485 1.043A.892.892 0 007 5.437v13.43c-.867.271-1.7.614-2.476 1.033a1.001 1.001 0 00.952 1.76c3.8-2.054 9.25-2.054 13.048 0a1.002 1.002 0 00.952-1.76A14.02 14.02 0 0017 18.864z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Gemini;
