import { createTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {

  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {

  }
}

export default createTheme({
  palette: {
    type: 'light',
    primary: {
      main: "rgba(255, 255, 255, 0.80)",
    },
  },
});
