import React from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SvgLogo from './Logo'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoContainer: {
      marginTop: "10%",
      textAlign: "center"
    },
    spinnerLogo: {
      position: "relative",
      width: '200px',
      height: '200px',
      marginLeft: "0",
      animation: "App-logo-spin infinite 5s linear",
    },
  })
)

const SpinnerContainer = styled('div')({
  position: "absolute",
  top: 0,
  width: "80vw",
  marginLeft: "10%",
  zIndex: 10,
  backgroundColor: "rgba(255, 255, 255, 0.7)"
});


export default function Spinner(props:any) {
  const classes = useStyles();
  return (
    <SpinnerContainer>
      <div className={classes.logoContainer}>
        <SvgLogo className={`${classes.spinnerLogo}` }/>
      </div>
    </SpinnerContainer>
  )
}
