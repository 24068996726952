import * as React from "react";

function Aries(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      aria-labelledby="component-svg-aries"
      role="img"
      {...props}
    >
      <title id="component-svg-aries">Aries</title>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path fill="none" opacity={0.3} d="M0 0h24v24H0z" />
          <path d="M20 4.08a5.19 5.19 0 00-7.44 0 4.79 4.79 0 00-.53.66 4.79 4.79 0 00-.53-.66 5.19 5.19 0 00-7.5 0 5.35 5.35 0 00-1.12 1.7 5.51 5.51 0 000 4.17A5.24 5.24 0 004 11.65a1 1 0 101.43-1.39 3.31 3.31 0 01-.7-1.07 3.49 3.49 0 01-.23-1.32 3.45 3.45 0 01.26-1.32 3.31 3.31 0 01.7-1.07 3 3 0 011-.71 3.16 3.16 0 012.54 0 3 3 0 011 .71 3.31 3.31 0 01.7 1.07 3.45 3.45 0 01.3 1.32v12.62a1 1 0 002 0V7.87a3.45 3.45 0 01.26-1.32A3.31 3.31 0 0114 5.48a3 3 0 011-.71 3.16 3.16 0 012.52 0 3 3 0 011 .71 3.31 3.31 0 01.7 1.07 3.45 3.45 0 01.26 1.32 3.49 3.49 0 01-.26 1.32 3.31 3.31 0 01-.7 1.07A1 1 0 1020 11.65 5.24 5.24 0 0021.09 10a5.48 5.48 0 000-4.16A5.28 5.28 0 0020 4.08z" />
        </g>
      </g>
    </svg>
  );
}

export default Aries;
