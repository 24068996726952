import React from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    demoVideo: {
      marginTop: "2%",
      ["@media (max-width:1024px)"]: {
        marginTop: "10%"
      },
      ["@media (max-width:990px)"]: {
        width: "96vw",
        height: "56vw",
      },
      ["@media (max-width:700px)"]: {
        marginTop: "18%"
      }
    }
  })
)

const DemoContainer = styled('div')({
  width: "100vw",
  height: "50vh",
  position: 'relative',
  textAlign: "center"
});

export default function Demo(props:any) {
  const classes = useStyles();


  return (
    <DemoContainer>
      <iframe width="853"
              height="480"
              src="https://www.youtube.com/embed/kQzShCWDJms"
              title="Astrology for Days demo video with features & background"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className={classes.demoVideo}>
      </iframe>
    </DemoContainer>
  )
}
