import React from 'react';
import { useEffect, useState } from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pdf: {
      width: "94%",
      height: "90%"
    },
    closer: {
      background: "none",
      color: "inherit",
      border: "none",
      padding: 0,
      cursor: "pointer",
      "& svg": {
        height: "2em",
        width: "2em"
      }
    }
  })
)

const EphemerisContainer = styled('div')({
  width: "100%",
  height: "100%",
  overflow: "scroll",
  padding: '10px',
});

export default function Ephemeris(props:any) {
  const classes = useStyles();
  const day = props.month
  const close = props.closeEphemeris;
  const [pdf, setPdfUrl] = useState("");

  useEffect(() => {
    const month = day.toLocaleString('en-us', {  month: 'long' });
    const year = day.getFullYear();
    var storageRef = firebase.storage().ref();

    storageRef.child(`swiss-ephemeris/${year}/${month}.pdf`)
              .getDownloadURL()
              .then(function(url) {
                url = url + "&navpanes=0"
                setPdfUrl(url)
              }).catch(function(error) {
                console.log("couldn't get pdf")
              })
  },[])

  return (
    <EphemerisContainer>
      <div>
        <button className={classes.closer} onClick={() => close()}>
          <SvgIcon component={CloseIcon} viewBox="0 0 40 40"/>
        </button>
      </div>
      <iframe className={classes.pdf} src={pdf}></iframe>
    </EphemerisContainer>
  )
}
